import * as React from 'react';
import { useIntl } from 'react-intl';
import { useBlocker } from 'react-router-dom';

import ConfirmationDialog from 'Components/ConfirmationDialog';

const NavigationBlocker = ({ blocked, children }: INavigationBlocker) => {
  const [alertOpen, setAlertOpen] = React.useState<boolean>(false);

  const { formatMessage } = useIntl();

  React.useEffect(() => {
    const relocationEventHandler = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      return formatMessage({ id: 'general.saveOrCancel' });
    };

    if (blocked) {
      window.addEventListener('beforeunload', relocationEventHandler);
    } else {
      window.removeEventListener('beforeunload', relocationEventHandler);
    }

    return () => window.removeEventListener('beforeunload', relocationEventHandler);
  }, [blocked]);

  useBlocker(() => {
    blocked && setAlertOpen(true);
    return blocked;
  });

  return (
    <>
      <ConfirmationDialog
        open={alertOpen}
        text={formatMessage({ id: 'general.saveOrCancel' })}
        onlyConfirm
        centeredText
        confirm={{ callback: () => setAlertOpen(false), text: 'general.ok' }}
      />
      {children}
    </>
  );
};

interface INavigationBlocker {
  blocked: boolean;
  children: JSX.Element;
}

export default NavigationBlocker;
