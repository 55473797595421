import { fm } from 'Components/FormatMessage';
import { Container, Item } from 'Components/Grid';
import HistoryRowVerticalItem from 'Components/HistoryRowVerticalItem';
import HistorySection from 'Components/HistorySection';
import { IHistoryContext, withHistoryContext } from 'Containers/FormContextHandler';
import { isNil } from 'ramda';
import * as React from 'react';
import { newButton } from 'Routes/Diagnosis/utils/functions';

const SymptomsDetailsHistory = ({
  documents = [],
  startEdit,
  treatmentPeriod,
  setTreatmentId,
}: IHistoryContext & { treatmentPeriod: ININMTTreatmentPeriod; setTreatmentId: (id: string) => void }): JSX.Element => {
  const treatmentId = treatmentPeriod?._id;

  const symptomsDetails: ISymptomsDetails = documents.find(
    (d) => d._type === 'symptomsDetails' && d.treatmentPeriodId === treatmentId,
  );

  return (
    <HistorySection
      headerText={<span style={{ fontSize: '2.2rem' }}>{fm('diagnosis.ninmt.symptomsDetails.title')}</span>}
      hasHistoryRows={false}
      newButton={
        startEdit
          ? newButton(
              'symptomsDetails',
              (e: React.MouseEvent): void => {
                setTreatmentId(treatmentId);
                startEdit(symptomsDetails ?? {}, 'symptomsDetails')(e);
              },
              'general.edit',
              undefined,
              true,
              !isNil(((symptomsDetails ?? {}) as IControlProps)._lockedFor) ||
                treatmentPeriod._editing ||
                !treatmentPeriod.date,
              isNil(((symptomsDetails ?? {}) as IControlProps)._lockedFor)
                ? 'diagnosis.ninmt.treatmentPeriodDisabledTooltip'
                : undefined,
              undefined,
              symptomsDetails,
            )
          : undefined
      }
    >
      <Container>
        <Item xs={0} md={3} lg={3} />
        <Item xs={12} md={9} lg={9}>
          <HistoryRowVerticalItem
            header={fm('diagnosis.ninmt.symptomsDetails.symptomsDetails')}
            headerWidth={5}
            value={symptomsDetails && symptomsDetails.symptomsDetails ? symptomsDetails.symptomsDetails : '-'}
          />
        </Item>
      </Container>
    </HistorySection>
  );
};

export default withHistoryContext(SymptomsDetailsHistory);
