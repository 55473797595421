import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { path } from 'ramda';

import FormSection from '../../../../../components/FormSection';
import FormRow from '../../../../../components/FormRow';
import InputHandler from '../../../../../components/InputHandler';
import { Container, Item } from '../../../../../components/Grid';

import { motorFunctionFields } from '../../../utils';
import { isPartialDate } from 'neuro-utils';

/**
 * JSX.Element component for date grids
 * @param startDate ~ Start date
 * @param endDate ~ End date
 */
const dateGrids = (startDate: JSX.Element, endDate?: JSX.Element): JSX.Element => (
  <Container>
    <Item xs={12} md={6}>
      {startDate}
    </Item>
    <Item xs={12} md={6}>
      {endDate}
    </Item>
  </Container>
);

/**
 * JSX.Element component for date input
 * @param header ~ Header
 * @param input ~ Input
 */
const dateInputs = (header: JSX.Element, input: JSX.Element): JSX.Element => (
  <div>
    <div style={{ marginBottom: '0.5rem' }}>{header}</div>
    <div style={{ fontWeight: 600, marginBottom: '2rem' }}>{input}</div>
  </div>
);

/**
 * Form section for one motorfunction field
 * @param motorfunction ~ Name of motorfunction field
 * @param formData ~ IFormData
 */
const Section = (motorfunction: string, formData: IFormData): JSX.Element => {
  const startDate = path([motorfunction + 'Start'], formData.document) as PartialDate | undefined;
  const endDate = path([motorfunction + 'End'], formData.document) as PartialDate | undefined;

  return (
    <FormRow title={'motorFunctionAndMobilityAid.motorfunction.' + motorfunction}>
      {dateGrids(
        dateInputs(
          <FormattedMessage id="motorFunctionAndMobilityAid.motorfunction.abilityGainedDate" />,
          <InputHandler type="PartialDate" editing={true} name={motorfunction + 'Start'} formData={formData} />,
        ),
        isPartialDate(startDate) || isPartialDate(endDate)
          ? dateInputs(
              <FormattedMessage id="motorFunctionAndMobilityAid.motorfunction.abilityLostDate" />,
              <InputHandler
                type="PartialDate"
                editing={true}
                name={motorfunction + 'End'}
                formData={formData}
                dateHook={{ dateHookFloor: startDate }}
              />,
            )
          : undefined,
      )}
    </FormRow>
  );
};

const MotorFunctionForm = ({ formData }: IOwnProps): JSX.Element => (
  <FormSection>
    {motorFunctionFields.map((field: string) => (
      <React.Fragment key={field}>{Section(field, formData)}</React.Fragment>
    ))}
  </FormSection>
);

interface IOwnProps {
  formData: IFormData<IMotorFunction>;
}

export default MotorFunctionForm;
