import { fm } from 'Components/FormatMessage';
import { Container, Item } from 'Components/Grid';
import HistoryRowVerticalItem from 'Components/HistoryRowVerticalItem';
import HistorySection from 'Components/HistorySection';
import { IHistoryContext, withHistoryContext } from 'Containers/FormContextHandler';
import { isNil } from 'ramda';
import * as React from 'react';
import { newButton } from 'Routes/Diagnosis/utils/functions';

const ReferringPhysicianAndUnitHistory = ({
  documents = [],
  startEdit,
  treatmentPeriod,
  setTreatmentId,
}: IHistoryContext & { treatmentPeriod: ININMTTreatmentPeriod; setTreatmentId: (id: string) => void }): JSX.Element => {
  const treatmentId = treatmentPeriod?._id;

  const referringPhysicianAndUnit: IReferringPhysicianAndUnit = documents.find(
    (d) => d._type === 'referringPhysicianAndUnit' && d.treatmentPeriodId === treatmentId,
  );

  return (
    <HistorySection
      headerText={<span style={{ fontSize: '2.2rem' }}>{fm('diagnosis.ninmt.referringPhysicianAndUnit.title')}</span>}
      hasHistoryRows={false}
      newButton={
        startEdit
          ? newButton(
              'referringPhysicianAndUnit',
              (e: React.MouseEvent): void => {
                setTreatmentId(treatmentId);
                startEdit(referringPhysicianAndUnit ?? {}, 'referringPhysicianAndUnit')(e);
              },
              'general.edit',
              undefined,
              true,
              !isNil(((referringPhysicianAndUnit ?? {}) as IControlProps)._lockedFor) ||
                treatmentPeriod._editing ||
                !treatmentPeriod.date,
              isNil(((referringPhysicianAndUnit ?? {}) as IControlProps)._lockedFor)
                ? 'diagnosis.ninmt.treatmentPeriodDisabledTooltip'
                : undefined,
              undefined,
              referringPhysicianAndUnit,
            )
          : undefined
      }
    >
      <Container>
        <Item xs={0} md={3} lg={3} />
        <Item xs={12} md={9} lg={9}>
          <HistoryRowVerticalItem
            header={fm('diagnosis.ninmt.referringPhysicianAndUnit.referringPhysician')}
            headerWidth={5}
            value={
              referringPhysicianAndUnit && referringPhysicianAndUnit.referringPhysician
                ? referringPhysicianAndUnit.referringPhysician
                : '-'
            }
          />
          <HistoryRowVerticalItem
            header={fm('diagnosis.ninmt.referringPhysicianAndUnit.unit')}
            headerWidth={5}
            value={referringPhysicianAndUnit && referringPhysicianAndUnit.unit ? referringPhysicianAndUnit.unit : '-'}
          />
        </Item>
      </Container>
    </HistorySection>
  );
};

export default withHistoryContext(ReferringPhysicianAndUnitHistory);
