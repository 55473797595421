import * as React from 'react';
import FormSection from '../../../../components/FormSection';
import InputHandler from '../../../../components/InputHandler';
import { seizureClassifications } from '../../../Diagnosis/utils/definitions';
import {
  formatOptionIndent,
  formatSeizureClassification,
  getOwnSeizureTypesClassifications,
  GroupHeader,
} from '../../utils';
import FormRow from '../../../../components/FormRow';
import { MenuItem } from '@mui/material';
import { isPartialDate } from 'neuro-utils';

const PatientReportedSeizureForm = ({
  fm,
  formData,
  editing,
}: {
  fm: (id: string) => string;
  formData: IFormData<IPatientReportedSeizure>;
  editing: boolean;
}): JSX.Element => {
  const controlOpts = ['other', 'notSeizure', 'return'];
  const controlOptsOG = ['other', 'notSeizure'];
  const originalSet = getOwnSeizureTypesClassifications();
  const [classificationOpts, setClassificationOpts] = React.useState<string[]>(
    getOwnSeizureTypesClassifications().concat(controlOptsOG),
  );
  const [classificationMenuOpen, setClassificationMenuOpen] = React.useState<boolean>(false);

  const onCloseClassification = (e: React.BaseSyntheticEvent): void => {
    if (e.target.dataset.value === 'other' || e.target.dataset.value === 'return') return;
    setClassificationMenuOpen(false);
  };

  // "null check"
  if (!fm || !formData) throw 'fm or formData is undefined';

  const classificationWithHeaders: string[] = [];
  classificationOpts.forEach((clas, i) => {
    // Add headers to all top level classifications that have children
    if (clas.length === 3 && classificationOpts[i + 1].match('^' + clas)) {
      classificationWithHeaders.push('header' + clas);
    }
    classificationWithHeaders.push(clas);
  });

  return (
    <>
      <FormSection>
        <FormRow title={'seizure.startDate'}>
          <InputHandler name="startDate" type="PartialDate" isNotCancellable editing={false} formData={formData} />
        </FormRow>
        <FormRow title={'seizure.reportTimeframe'}>
          <InputHandler
            name="reportTimeframe"
            type="CheckboxSingle"
            editing={false}
            option={'true'}
            showLabel={false}
            optionFormatter={(o) => fm(`general.${o}`)}
            formData={{
              document: { reportTimeframe: formData.document.endDate && isPartialDate(formData.document.endDate) },
              onChange: () => {
                formData?.onChange?.({ time: undefined });
                formData?.onChange?.({ endDate: undefined });
              },
            }}
            dependentFieldsList={() => ['time', 'endDate']}
          />
        </FormRow>
        <FormRow
          title={'seizure.startTime'}
          condition={!(formData.document.endDate && isPartialDate(formData.document.endDate))}
        >
          <InputHandler name="startTime" type="TimePicker" editing={false} formData={formData} />
        </FormRow>
        <FormRow
          title={'seizure.endDate'}
          condition={formData.document.endDate && isPartialDate(formData.document.endDate)}
        >
          <InputHandler name="endDate" type="PartialDate" editing={false} formData={formData} />
        </FormRow>
        <FormRow title={'seizure.classification'}>
          <InputHandler
            name="seizureType"
            type="Select"
            editing={!!editing}
            formData={{
              document: formData.document || {},
              onChange: (values: TOnChangeValues): void => {
                const name = Object.keys(values)[0];
                const value = values[name];
                if (controlOpts.includes(value as any)) {
                  switch (value) {
                    case 'other':
                      setClassificationOpts([...seizureClassifications, 'notSeizure', 'return']);
                      break;
                    case 'notSeizure':
                      formData.onChange && formData.onChange({ [name]: 'notSeizure' });
                      break;
                    case 'return':
                      setClassificationOpts([...originalSet, 'other', 'notSeizure']);
                      formData.onChange && formData.onChange({ [name]: null });
                      break;
                  }
                } else {
                  if (formData.onChange) {
                    formData.onChange({ [name]: value });
                    formData.onChange({ seizureName: undefined });
                  }
                }
              },
            }}
            options={classificationWithHeaders}
            optionFormatter={(o): string | JSX.Element => {
              return controlOpts.includes(`${o}`) ? (
                <span style={{ fontWeight: 400 }}>{fm(`seizure.${o}`)}</span>
              ) : (
                <span>
                  {classificationMenuOpen && seizureClassifications.every((c) => classificationOpts.includes(c))
                    ? formatOptionIndent(`${o} ${fm(`seizure.opts.${o}`)}`)
                    : classificationMenuOpen
                      ? formatSeizureClassification(o as TSeizureClassification, 'long')
                      : formatSeizureClassification(o as TSeizureClassification, 'short')}
                </span>
              );
            }}
            menuItemComponent={(o: string | number, optFormatter) => {
              const option = `${o}`;
              if (option.substring(0, 6) === 'header') {
                // Different menuitems for group headers
                return (
                  <GroupHeader
                    onClick={(e: React.MouseEvent) => {
                      e.stopPropagation();
                      e.preventDefault();
                    }}
                  >
                    <MenuItem disabled>
                      {optFormatter ? optFormatter(option.substring(6)) : option.substring(6)}
                    </MenuItem>
                  </GroupHeader>
                );
              }
              return (
                <MenuItem value={option} key={option}>
                  {optFormatter ? (
                    <div style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                      {optFormatter(option)}
                      {/** Show parent group for deep child items when big selection is open */}
                      {classificationMenuOpen &&
                      seizureClassifications.every((c) => classificationOpts.includes(c)) &&
                      option.split('.').length > 3 ? (
                        <>
                          <span style={{ padding: '0.4rem 0.5rem 0 0.5rem', fontSize: '1.2rem' }}>-</span>
                          <span style={{ paddingTop: '0.4rem', fontSize: '1.2rem' }}>
                            {fm('seizure.opts.' + option.split('.').slice(0, -1).join('.'))}
                          </span>
                        </>
                      ) : (
                        ''
                      )}
                    </div>
                  ) : (
                    option
                  )}
                </MenuItem>
              );
            }}
            width={40}
            minWidth={18}
            placeholder={'seizure.classificationPlaceholder'}
            open={classificationMenuOpen}
            onOpen={(): void => setClassificationMenuOpen(true)}
            onClose={onCloseClassification}
          />
          <div style={{ marginTop: '1rem', fontWeight: 600 }}>
            {formatSeizureClassification(formData.document.seizureType, 'long', true)}
          </div>
        </FormRow>
        <FormRow title={'seizure.count'}>
          <InputHandler
            name="count"
            type="NumberField"
            editing={false}
            formData={formData}
            placeholder={'seizure.countPlaceholder'}
            width={18}
          />
        </FormRow>
        <FormRow title={'seizure.medicComplianceShort'}>
          <InputHandler
            name="medicCompliance"
            type="Radio"
            editing={false}
            formData={formData}
            options={['yes', 'no', 'unknown']}
            optionFormatter={(o): string => fm(`general.${o}`)}
          />
        </FormRow>
        <FormRow title={'seizure.seizureMedicationGiven'}>
          <InputHandler
            name="seizureMedicationGiven"
            type="CheckboxSingle"
            editing={false}
            formData={formData}
            option={'true'}
            showLabel={false}
            optionFormatter={(o) => fm(`general.${o}`)}
          />
        </FormRow>
        <FormRow title={'patientReportedSeizure.freeDescription'}>
          <InputHandler name="freeDescription" type="TextArea" editing={false} formData={formData} maxLength={500} />
        </FormRow>
        <FormRow title={'patientReportedSeizure.status'}>
          <InputHandler
            name="status"
            type="Radio"
            editing={!!editing}
            formData={formData}
            options={['accepted', 'rejected']}
            optionFormatter={(o) => fm(`patientReportedSeizure.opts.status.${o}Single`)}
          />
        </FormRow>
      </FormSection>
    </>
  );
};

export default PatientReportedSeizureForm;
