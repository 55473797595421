import { isNil } from 'ramda';
import * as React from 'react';
import FormRow from '../../../../../components/FormRow';
import FormSection from '../../../../../components/FormSection';
import InputHandler from '../../../../../components/InputHandler';

const DMDform = ({ editing, formData }: IOwnProps): JSX.Element => {
  return (
    <React.Fragment>
      <FormRow title="diagnosis.symptomOnsetDate">
        <InputHandler
          type="PartialDate"
          editing={!!editing}
          name="symptomOnsetDate"
          formData={formData}
          dateDefault="now"
        />
      </FormRow>
      <FormSection header="diagnosis.dmd">
        <FormRow title="diagnosis.dmdGeneticConfirmation" id={'geneticConfirmation'}>
          <InputHandler
            type="Radio"
            editing={!!editing}
            name="dmdGeneticConfirmation"
            formData={formData}
            preset="yesno"
            dependentFieldsList={(): string[] => [
              'dmdMutationName',
              'dmdDeletionOfExons',
              'dmdDuplicationOfExons',
              'dmdBoundariesKnown',
              'dmdExonsSequenced',
              'dmdExonsTestedInMaleRelative',
            ]}
            dependentFieldsRemovalWarning={true}
          />
        </FormRow>
        {(formData.document as IDiagnosis).dmdGeneticConfirmation === true ? (
          <React.Fragment>
            <FormRow title="diagnosis.dmdMutationName">
              <InputHandler
                type="TextField"
                editing={!!editing}
                name="dmdMutationName"
                formData={{
                  onChange: (values: TOnChangeValues): void => {
                    if ((values['dmdMutationName'] as string)?.substring(0, 2) !== 'c.') return;
                    formData.onChange?.(values);
                  },
                  document: {
                    dmdMutationName: isNil(formData.document.dmdMutationName)
                      ? 'c.'
                      : formData.document.dmdMutationName,
                  },
                }}
                minCaretSelection={2}
              />
            </FormRow>
            <FormRow title="diagnosis.dmdDeletionOfExons" id={'exonsDeletion'}>
              <InputHandler
                type="Radio"
                editing={!!editing}
                name="dmdDeletionOfExons"
                formData={formData}
                preset="yesNoUnknownForceEn"
              />
            </FormRow>
            <FormRow title="diagnosis.dmdDuplicationOfExons" id={'exonsDuplication'}>
              <InputHandler
                type="Radio"
                editing={!!editing}
                name="dmdDuplicationOfExons"
                formData={formData}
                preset="yesNoUnknownForceEn"
              />
            </FormRow>
            <FormRow title="diagnosis.dmdBoundariesKnown" id={'dmdBoundaries'}>
              <InputHandler
                type="Radio"
                editing={!!editing}
                name="dmdBoundariesKnown"
                formData={formData}
                preset="yesNoUnknownForceEn"
              />
            </FormRow>
            <FormRow title="diagnosis.dmdExonsSequenced" id={'exonsSequenced'}>
              <InputHandler
                type="Radio"
                editing={!!editing}
                name="dmdExonsSequenced"
                formData={formData}
                preset="yesNoUnknownForceEn"
              />
            </FormRow>
            <FormRow title="diagnosis.dmdExonsTestedInMaleRelative" id={'exonsTestedMaleRelative'}>
              <InputHandler
                type="Radio"
                editing={!!editing}
                name="dmdExonsTestedInMaleRelative"
                formData={formData}
                preset="yesNoUnknownForceEn"
              />
            </FormRow>
          </React.Fragment>
        ) : undefined}
      </FormSection>
    </React.Fragment>
  );
};

interface IOwnProps {
  editing?: string;
  formData: IFormData<IDiagnosis>;
}

export default DMDform;
