import { filter, length, path } from 'ramda';

/**
 * Returns whether MSA is probable
 * @param  {IMSA} d - MSA document
 * @returns {'yes' | 'no' | 'unknown'} If MSA is probable
 */
export const probableMSA = (d: IMSA): 'yes' | 'no' | 'unknown' => {
  const yes =
    (d.probableAutonomicFailureInvolvingUrinaryIncontinence === 'yes' ||
      d.probableOrthostaticDecreaseOfBloodPressure === 'yes') &&
    (d.probablePoorlyLevodopaResponsiveParkinsonism === 'yes' ||
      d.gaitAtaxiaWithCerebellarDysarthria === 'yes' ||
      d.limbAtaxia === 'yes' ||
      d.cerebellarOculomotorDysfunction === 'yes');
  const no =
    (d.probableAutonomicFailureInvolvingUrinaryIncontinence === 'no' &&
      d.probableOrthostaticDecreaseOfBloodPressure === 'no') ||
    (d.probablePoorlyLevodopaResponsiveParkinsonism === 'no' &&
      d.gaitAtaxiaWithCerebellarDysarthria === 'no' &&
      d.limbAtaxia === 'no' &&
      d.cerebellarOculomotorDysfunction === 'no');
  if (yes) return 'yes';
  if (no) return 'no';
  return 'unknown';
};

const someOf = (fields: string[], condition: string, d: IMSA): boolean =>
  fields.some((f) => (d as { [key: string]: any })[f] === condition);

const allOf = (fields: string[], condition: string, d: IMSA): boolean => {
  const filtered = fields.filter((f) => (d as { [key: string]: any })[f] === condition);
  return fields.length === filtered.length;
};

/**
 * Returns whether MSA is possible
 * @param  {IMSA} d - MSA document
 * @returns {'yes' | 'no' | 'unknown'} If MSA is possible
 */
export const possibleMSA = (d: IMSA): 'yes' | 'no' | 'unknown' => {
  const parkinsonism = (): 'yes' | 'no' | 'unknown' => {
    const fields = ['possibleBradykinesiaWithRigidity', 'possibleTremor', 'possiblePosturalInstability'];

    return someOf(fields, 'yes', d) ? 'yes' : allOf(fields, 'no', d) ? 'no' : 'unknown';
  };

  const cerebellar = (): 'yes' | 'no' | 'unknown' => {
    const fields = ['gaitAtaxiaWithCerebellarDysarthria', 'limbAtaxia', 'cerebellarOculomotorDysfunction'];

    return someOf(fields, 'yes', d) ? 'yes' : allOf(fields, 'no', d) ? 'no' : 'unknown';
  };

  const autonomic = (): 'yes' | 'no' | 'unknown' => {
    const fields = [
      'possibleOtherwiseUnexplainedUrinaryUrgency',
      'possibleFrequencyOrIncompleteBladderEmptying',
      'possibleErectileDysfunctionInMales',
      'significantOrthostaticBloodPressure',
    ];

    return someOf(fields, 'yes', d) ? 'yes' : allOf(fields, 'no', d) ? 'no' : 'unknown';
  };

  const additional = (): 'yes' | 'no' | 'unknown' => {
    const fields = [
      'possibleBabinskiSignWithHyperreflexia',
      'possibleStridor',
      'possibleMSAPRapidlyProgressiveParkinsonism',
      'possibleMSAPPoorResponseToLevodopa',
      'possibleMSAPPosturalInstabilityWithin3yOfMotorOnset',
      'possibleMSAPGaitAtaxiaCerebellarDysarthriaLimbAtaxiaOrCerebellarOculomotorDysfunction',
      'possibleMSAPDysphagiaWithin5yOfMotorOnset',
      'possibleMSAPAtrophyOnMRIOfPutamenMiddleCerebellarPedunclePonsOrCerebellum',
      'possibleMSAPHypometabolismOnFDGPETInPutamenBrainstemOrCerebellum',
      'possibleMSACParkinsonism',
      'possibleMSACAtrophyOnMRIOfPutamenMiddleCerebellarPeduncleOrPons',
      'possibleMSACHypometabolismOnFDGPETInPutamen',
      'possibleMSACPresynapticNigrostriatalDopaminergicDenervationOnSPECTOrPET',
    ];

    return someOf(fields, 'yes', d) ? 'yes' : allOf(fields, 'no', d) ? 'no' : 'unknown';
  };

  if ((parkinsonism() === 'yes' || cerebellar() === 'yes') && autonomic() === 'yes' && additional() === 'yes')
    return 'yes';
  if ((parkinsonism() === 'no' && cerebellar() === 'no') || autonomic() === 'no' || additional() === 'no') return 'no';
  return 'unknown';
};

const supportingFeaturesFields = [
  'supportingOrofacialDystonia',
  'supportingDisproportionateAntecollis',
  'supportingCamptocormia',
  'supportingContracturesOfHandsOrFeet',
  'supportingInspiratorySighs',
  'supportingSevereDysphonia',
  'supportingSevereDysatrhria',
  'supportingNewOrIncreasedSnoring',
  'supportingColdHandsAndFeet',
  'supportingPathologicLaughterOrCrying',
  'supportingJerkyMyoclonicPosturalActionTremor',
];

const nonSupportingFeaturesFields = [
  'nonsupportingClassicPillrollingRestTremor',
  'nonsupportingClinicallySignificantNeuropathy',
  'nonsupportingHallucinationsNotInducedByDrugs',
  'nonsupportingOnsetAfter75y',
  'nonsupportingFamilyHistoryOfAtaxiaOrParkinsonism',
  'nonsupportingDementia',
  'nonsupportingWhiteMatterLesionsSuggestingMultipleSclerosis',
];

/**
 * Counts the number of MSA supporting features answers (yes, no or unknown)
 * @param {IMSA} document - MSA document to be checked
 * @param {TYesNoUnknown} answer - The answers to count
 * @returns {number} - Number of answers
 */
export const numberOfSupportingFeatures = (document: IMSA, answer: TYesNoUnknown): number =>
  length(filter((feature: string) => path([feature], document) === answer, supportingFeaturesFields));

/**
 * Counts the number of MSA nonsupporting features answers (yes, no or unknown)
 * @param {IMSA} document - MSA document to be checked
 * @param {TYesNoUnknown} answer - The answers to count
 * @returns {number} - Number of answers
 */
export const numberOfNonSupportingFeatures = (document: IMSA, answer: TYesNoUnknown): number =>
  length(filter((feature: string) => path([feature], document) === answer, nonSupportingFeaturesFields));
