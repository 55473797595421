import { intersection, path } from 'ramda';

export const drawSiblingDiagnosisAge = (document: IBackground | { [field: string]: any }): boolean => {
  const field: string[] =
    path(['familyMembersParkinson', 'value'], document) || path(['familyMembersParkinson'], document) || [];
  return intersection(field ?? [], ['siblings', 'twinSibling', 'twinSister', 'twinBrother']).length > 0;
};
export const drawOccupation = (document: IBackground | { [field: string]: any }): boolean => {
  const field: string[] = path(['employment', 'value'], document) || path(['employment'], document) || [];
  return intersection(field ?? [], ['employed', 'fullTime', 'partTime']).length > 0;
};
export const drawSickLeaveDays = (document: IBackground | { [field: string]: any }): boolean => {
  const field: string[] = path(['employment', 'value'], document) || path(['employment'], document) || [];
  return intersection(field ?? [], ['sickLeave']).length > 0;
};
