import { isNil } from 'ramda';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import FormRow from '../../../../../components/FormRow';
import { Container, Item } from '../../../../../components/Grid';
import InputHandler from '../../../../../components/InputHandler';
import { ReferenceText } from '../../../../Diagnosis/utils/styled';

import { idaClassificationMonitoring2020, relapseScore, t1EnchancedChange, t2Change } from '../../../utils';
import { isPartialDate, nowPartialDate } from 'neuro-utils';
import {
  getRelapseCount,
  getMRIChanges,
  idaClassificationMonitoring2024,
} from 'Routes/InflammatoryDiseaseActivity/utils/idaClassification2024';

const optFormatter = (name: string | number): JSX.Element => (
  <FormattedMessage id={'inflammatoryDiseaseActivity.opts.' + name} />
);

const relapseCountLast12MonthsOnChange = (
  version: '2020' | '2024',
  onChange: IOwnProps['formData']['onChange'],
  relapseDocs?: Array<IRelapse>,
  date?: PartialDate,
): void =>
  onChange &&
  onChange({
    relapseCountLast12Months: {
      2020: relapseScore(relapseDocs, date),
      2024: getRelapseCount(date ?? nowPartialDate(), relapseDocs ?? [], 'monitoring'),
    }[version],
  });

const t1GadoliniumEnhancedActiveChanges = (
  version: '2020' | '2024',
  onChange: IOwnProps['formData']['onChange'],
  mriDocs?: Array<IMRI>,
  date?: PartialDate,
): void =>
  onChange &&
  onChange({
    T1GadoliniumEnhancedActiveChanges: {
      2020: t1EnchancedChange(mriDocs, date),
      2024: getMRIChanges(date ?? nowPartialDate(), mriDocs ?? [], 'T1GadoliniumEnhanced', 'monitoring'),
    }[version],
  });

const t2ChangesOnChange = (
  onChange: IOwnProps['formData']['onChange'],
  mriDocs?: Array<IMRI>,
  date?: PartialDate,
): void => onChange && onChange({ T2ChangesNeworExpanding: t2Change('monitoring', mriDocs, date) });

const t2ChangesNewOnChange = (
  onChange: IOwnProps['formData']['onChange'],
  mriDocs?: Array<IMRI>,
  date?: PartialDate,
): void =>
  onChange &&
  onChange({
    T2ChangesNew: getMRIChanges(date ?? nowPartialDate(), mriDocs ?? [], 'T2New', 'monitoring'),
  });

const inflammatoryDiseaseActivity2020OnChange = (
  onChange: IOwnProps['formData']['onChange'],
  document: IInflammatoryDiseaseActivityMonitoring,
): void =>
  onChange &&
  onChange({
    inflammatoryDiseaseActivity: idaClassificationMonitoring2020(document),
  });

const inflammatoryDiseaseActivity2024OnChange = (
  onChange: IOwnProps['formData']['onChange'],
  document: IInflammatoryDiseaseActivityMonitoring2024,
): void =>
  onChange &&
  onChange({
    inflammatoryDiseaseActivity: idaClassificationMonitoring2024(document),
  });

const MonitoringForm = ({ formData, viewing, doc, relapseDocs, mriDocs }: IOwnProps): JSX.Element | null => {
  const { onChange, document } = formData;
  const idaClassificationChange = React.useRef(!document.inflammatoryDiseaseActivity);
  const date = document.date;
  const relapse = document.relapseCountLast12Months;

  const isNewDoc = !viewing && doc._commitsLength === 1;

  // "MS-tauti. Käypä hoito -suositus. (Julkaistu: 6.2.2024)."
  const versionIs2024 = (
    d: typeof document,
  ): d is IFormData<IInflammatoryDiseaseActivityMonitoring2024>['document'] => {
    return isNewDoc || d.version === '2024';
  };

  // "MS-tauti: Käypä hoito -suositus (Julkaistu: 23.01.2020)."
  const versionIs2020 = (d: typeof document): d is IFormData<IInflammatoryDiseaseActivityMonitoring>['document'] => {
    return !isNewDoc && (!d.version || d.version === '2020');
  };

  const isMountRender = React.useRef<boolean>(true);

  const [version, setVersion] = React.useState<'2020' | '2024' | undefined>(undefined);

  React.useEffect(() => {
    if (isMountRender.current) {
      isMountRender.current = false;
      if (viewing) setVersion(versionIs2024(document) ? '2024' : '2020');
      return;
    }
    setVersion(versionIs2024(document) ? '2024' : '2020');
    if (!viewing && isNewDoc && !document.version) {
      onChange?.({ version: '2024' });
    }
  }, [isMountRender.current, versionIs2020(document), versionIs2024(document)]);

  const t2 = versionIs2020(document) ? document.T2ChangesNeworExpanding : undefined;
  const t2New = versionIs2024(document) ? document.T2ChangesNew : undefined;
  const t1 = document.T1GadoliniumEnhancedActiveChanges;

  // For useEffect dependency comparisons
  const relapseDocsString = React.useMemo(() => JSON.stringify(relapseDocs), [relapseDocs]);
  const mriDocsString = React.useMemo(() => JSON.stringify(mriDocs), [mriDocs]);

  // Call onChange funtions only when certain values are not defined or has changed between renders
  // relapseCountLast12Months
  React.useEffect(() => {
    if (!viewing && version && isNil(relapse)) relapseCountLast12MonthsOnChange(version, onChange, relapseDocs, date);
  }, [date, relapse, relapseDocsString]);

  // T2ChangesNeworExpanding
  React.useEffect(() => {
    if (!viewing && version === '2020' && isNil(t2)) t2ChangesOnChange(onChange, mriDocs, date);
  }, [date, t2, mriDocsString]);

  // T2ChangesNew
  React.useEffect(() => {
    if (!viewing && version === '2024' && isNil(t2New)) t2ChangesNewOnChange(onChange, mriDocs, date);
  }, [date, t2New, mriDocsString]);

  // T1GadoliniumEnhancedActiveChanges
  React.useEffect(() => {
    if (!viewing && version && isNil(t1)) t1GadoliniumEnhancedActiveChanges(version, onChange, mriDocs, date);
  }, [date, t1, mriDocsString]);

  // Update IDA whenever these 3 values change, if the IDA value doesn't match the calculated value
  React.useEffect(() => {
    if (!viewing && idaClassificationChange.current) {
      if (
        version &&
        versionIs2024(document) &&
        document.inflammatoryDiseaseActivity !== idaClassificationMonitoring2024(document)
      ) {
        inflammatoryDiseaseActivity2024OnChange(onChange, document);
      }
      if (
        version &&
        versionIs2020(document) &&
        document.inflammatoryDiseaseActivity !== idaClassificationMonitoring2020(document)
      ) {
        inflammatoryDiseaseActivity2020OnChange(onChange, document);
      }
    }
  }, [relapse, t2, t2New, t1]);

  const handleChange = (values: TOnChangeValues): void => {
    onChange && onChange(values);
    idaClassificationChange.current = true;
  };

  let relapseCountLast12MonthsOptions: string[] | undefined = undefined;
  let T1GadoliniumEnhancedActiveChangesOptions: string[] | undefined = undefined;
  let inflammatoryDiseaseActivityOptions: string[] | undefined = undefined;

  // Pick options for fields that are shared between 2020 and 2024 versions
  if (versionIs2024(document)) {
    relapseCountLast12MonthsOptions = ['0', '≥1'];
    T1GadoliniumEnhancedActiveChangesOptions = ['0', '≥1', 'unknown'];
    inflammatoryDiseaseActivityOptions = ['veryActiveMS', 'noIndicationOfVeryActiveMS', 'unknown'];
  } else if (versionIs2020(document)) {
    relapseCountLast12MonthsOptions = ['0', '1', '≥1'];
    T1GadoliniumEnhancedActiveChangesOptions = ['0', '1', '>1', 'unknown'];
    inflammatoryDiseaseActivityOptions = ['stableMS', 'activeMS', 'veryActiveMS', 'unknown'];
  }

  const dateAndRelapseDocsExist = isPartialDate(date) && Array.isArray(relapseDocs);
  const dateAndMriDocsExist = isPartialDate(date) && Array.isArray(mriDocs);

  type PartialRecord<K extends keyof any, T> = Partial<Record<K, T>>;

  const automaticallyCalculatedValues: PartialRecord<keyof typeof document, any> = {};

  // Set automatically calculated values based on form version
  if (versionIs2024(document)) {
    if (dateAndRelapseDocsExist) {
      if (document.relapseCountLast12Months !== getRelapseCount(date, relapseDocs, 'monitoring')) {
        automaticallyCalculatedValues.relapseCountLast12Months = getRelapseCount(date, relapseDocs, 'monitoring');
      }
    }
    if (dateAndMriDocsExist) {
      (['T2New', 'T1GadoliniumEnhanced'] as ('T2New' | 'T1GadoliniumEnhanced')[]).forEach((type) => {
        const key = (
          type === 'T2New' ? 'T2ChangesNew' : 'T1GadoliniumEnhancedActiveChanges'
        ) as keyof typeof automaticallyCalculatedValues;
        if (document[key] !== getMRIChanges(date, mriDocs, type, 'monitoring')) {
          automaticallyCalculatedValues[key] = getMRIChanges(date, mriDocs, type, 'monitoring');
        }
      });
    }
    if (document.inflammatoryDiseaseActivity !== idaClassificationMonitoring2024(document)) {
      automaticallyCalculatedValues.inflammatoryDiseaseActivity = idaClassificationMonitoring2024(document);
    }
  } else if (versionIs2020(document)) {
    if (document.relapseCountLast12Months !== relapseScore(relapseDocs, date)) {
      automaticallyCalculatedValues.relapseCountLast12Months = relapseScore(relapseDocs, date);
    }
    if (document.T2ChangesNeworExpanding !== t2Change('monitoring', mriDocs, date)) {
      automaticallyCalculatedValues.T2ChangesNeworExpanding = t2Change('monitoring', mriDocs, date);
    }
    if (document.T1GadoliniumEnhancedActiveChanges !== t1EnchancedChange(mriDocs, date)) {
      automaticallyCalculatedValues.T1GadoliniumEnhancedActiveChanges = t1EnchancedChange(mriDocs, date);
    }
    if (document.inflammatoryDiseaseActivity !== idaClassificationMonitoring2020(document)) {
      automaticallyCalculatedValues.inflammatoryDiseaseActivity = idaClassificationMonitoring2020(document);
    }
  }

  return version ? (
    <React.Fragment>
      <FormRow title="general.date">
        <InputHandler
          type="PartialDate"
          editing={!viewing}
          name="date"
          formData={{
            onChange: handleChange,
            document: document,
          }}
          dateDefault="now"
          isNotCancellable={true}
        />
      </FormRow>
      <FormRow
        title="inflammatoryDiseaseActivity.relapseCountLast12Months"
        description={
          <FormattedMessage
            id={`inflammatoryDiseaseActivity.relapseCountLast12MonthsInfo${versionIs2024(document) ? 2024 : 2020}`}
          />
        }
      >
        <Container>
          <Item xs={7}>
            <InputHandler
              type="Radio"
              editing={!viewing}
              name="relapseCountLast12Months"
              formData={{
                onChange: handleChange,
                document: document,
              }}
              options={relapseCountLast12MonthsOptions}
              optionFormatter={(id: string | number) =>
                optFormatter(id === '≥1' && versionIs2020(document) ? '≥1RelapseDifficultyLevelConsidered' : id)
              }
            />
          </Item>
          <Item xs={5}>
            {!viewing && automaticallyCalculatedValues.relapseCountLast12Months ? (
              <div>
                <FormattedMessage id="inflammatoryDiseaseActivity.automaticallyCalculatedValue" />
                <span>{': '}</span>
                <span>{optFormatter(automaticallyCalculatedValues.relapseCountLast12Months)}</span>
              </div>
            ) : undefined}
          </Item>
        </Container>
      </FormRow>
      <FormRow
        title="inflammatoryDiseaseActivity.T2ChangesNeworExpanding"
        description={<FormattedMessage id="inflammatoryDiseaseActivity.T2ChangesNeworExpandingInfo" />}
        condition={versionIs2020(document)}
      >
        <Container>
          <Item xs={7}>
            <InputHandler
              type="Radio"
              editing={!viewing}
              name="T2ChangesNeworExpanding"
              formData={{
                onChange: handleChange,
                document: document,
              }}
              options={['0', '1-4', '≥5', 'unknown']}
              optionFormatter={optFormatter}
            />
          </Item>
          <Item xs={5}>
            {!viewing && automaticallyCalculatedValues.T2ChangesNeworExpanding ? (
              <div>
                <FormattedMessage id="inflammatoryDiseaseActivity.automaticallyCalculatedValue" />
                <span>{': '}</span>
                <span>{optFormatter(automaticallyCalculatedValues.T2ChangesNeworExpanding)}</span>
              </div>
            ) : undefined}
          </Item>
        </Container>
      </FormRow>
      <FormRow
        title="inflammatoryDiseaseActivity.T2ChangesNew"
        description={<FormattedMessage id="inflammatoryDiseaseActivity.T2ChangesNewInfo" />}
        condition={versionIs2024(document)}
      >
        <Container>
          <Item xs={7}>
            <InputHandler
              type="Radio"
              editing={!viewing}
              name="T2ChangesNew"
              formData={{
                onChange: handleChange,
                document: document,
              }}
              options={['0', '1', '≥2', 'unknown']}
              optionFormatter={optFormatter}
            />
          </Item>
          <Item xs={5}>
            {!viewing && automaticallyCalculatedValues['T2ChangesNew' as keyof typeof automaticallyCalculatedValues] ? (
              <div>
                <FormattedMessage id="inflammatoryDiseaseActivity.automaticallyCalculatedValue" />
                <span>{': '}</span>
                <span>
                  {optFormatter(
                    automaticallyCalculatedValues['T2ChangesNew' as keyof typeof automaticallyCalculatedValues],
                  )}
                </span>
              </div>
            ) : undefined}
          </Item>
        </Container>
      </FormRow>
      <FormRow
        title={`inflammatoryDiseaseActivity.T1GadoliniumEnhancedActiveChanges${versionIs2024(document) ? 2024 : 2020}`}
        description={
          <FormattedMessage
            id={`inflammatoryDiseaseActivity.T1GadoliniumEnhancedActiveChangesInfo${
              versionIs2024(document) ? 2024 : 2020
            }`}
          />
        }
      >
        <Container>
          <Item xs={7}>
            <InputHandler
              type="Radio"
              editing={!viewing}
              name="T1GadoliniumEnhancedActiveChanges"
              formData={{
                onChange: handleChange,
                document: document,
              }}
              options={T1GadoliniumEnhancedActiveChangesOptions}
              optionFormatter={optFormatter}
            />
          </Item>
          <Item xs={5}>
            {!viewing && automaticallyCalculatedValues.T1GadoliniumEnhancedActiveChanges ? (
              <div>
                <FormattedMessage id="inflammatoryDiseaseActivity.automaticallyCalculatedValue" />
                <span>{': '}</span>
                <span>{optFormatter(automaticallyCalculatedValues.T1GadoliniumEnhancedActiveChanges)}</span>
              </div>
            ) : undefined}
          </Item>
        </Container>
      </FormRow>
      <FormRow
        title="inflammatoryDiseaseActivity.inflammatoryDiseaseActivity"
        description={<FormattedMessage id="inflammatoryDiseaseActivity.inflammatoryDiseaseActivityInfo" />}
      >
        <Container>
          <Item xs={7}>
            <InputHandler
              type="Radio"
              editing={!viewing}
              name="inflammatoryDiseaseActivity"
              formData={formData}
              options={inflammatoryDiseaseActivityOptions}
              optionFormatter={optFormatter}
            />
          </Item>
          <Item xs={5}>
            {!viewing && automaticallyCalculatedValues.inflammatoryDiseaseActivity ? (
              <div>
                <FormattedMessage id="inflammatoryDiseaseActivity.automaticallyCalculatedValue" />
                <span>{': '}</span>
                <span>{optFormatter(automaticallyCalculatedValues.inflammatoryDiseaseActivity)}</span>
              </div>
            ) : undefined}
          </Item>
        </Container>
      </FormRow>
      <ReferenceText>
        <FormattedMessage id={`inflammatoryDiseaseActivity.referenceText${versionIs2024(document) ? 2024 : 2020}`} />
      </ReferenceText>
    </React.Fragment>
  ) : null;
};

interface IOwnProps {
  formData: IFormData<IInflammatoryDiseaseActivityMonitoring | IInflammatoryDiseaseActivityMonitoring2024>;
  viewing?: string;
  doc: IInflammatoryDiseaseActivityMonitoring | IInflammatoryDiseaseActivityMonitoring2024;
  relapseDocs?: IRelapse[];
  mriDocs?: IMRI[];
}

export default MonitoringForm;
