import * as React from 'react';
import { path, find, filter } from 'ramda';

import DocumentHeader from '../../../components/DocumentHeader';
import DocumentWrapper from '../../../components/DocumentWrapper';
import FormEditingHandler from '../../../containers/FormEditingHandler';

import MotorFunctionHistory from './HistoryRowData/MotorFunction';
import MobilityAidHistory from './HistoryRowData/Aid';
import Form from './Form';

const docType = (documents: Array<TDocument>, editing: string, view?: IView): string | undefined =>
  documents && editing
    ? path(
        ['_type'],
        find((d: TDocument) => d._id === editing, documents),
      ) || undefined
    : documents && view?.viewing
      ? path(
          ['_type'],
          find((d: TDocument) => d._id === view?.viewing, documents),
        ) || undefined
      : undefined;

const MotorFunction = ({ documents }: IOwnProps): JSX.Element => {
  const motorfunctionDocs = documents && (filter((d) => d._type === 'motorfunction', documents) as IMotorFunction[]);
  const mobilityAidDocs = documents && (filter((d) => d._type === 'mobilityAid', documents) as IAid[]);

  return (
    <FormEditingHandler name="motorFunctionAndMobilityAid" documents={documents}>
      {(editing, startEdit, formData, view): JSX.Element => (
        <DocumentWrapper>
          <DocumentHeader
            name="motorFunctionAndMobilityAid"
            headerId={
              docType(documents, editing, view) === 'motorfunction'
                ? 'motorFunctionAndMobilityAid.motorfunction.title'
                : docType(documents, editing, view) === 'mobilityAid'
                  ? 'motorFunctionAndMobilityAid.aids.title'
                  : 'motorFunctionAndMobilityAid.title'
            }
            editing={editing}
            editButtons={<div />}
          />

          {editing ? (
            <Form
              formData={formData}
              document={documents && (find((d: TDocument) => d._id === editing, documents) as TDocument)}
            />
          ) : null}

          {!editing ? (
            <>
              <MotorFunctionHistory documents={motorfunctionDocs} startEdit={startEdit} />
              <MobilityAidHistory documents={mobilityAidDocs} startEdit={startEdit} />
            </>
          ) : null}
        </DocumentWrapper>
      )}
    </FormEditingHandler>
  );
};

type TDocument = IMotorFunction | IAid;

interface IOwnProps {
  documents: Array<TDocument>;
}

export default MotorFunction;
