import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import HistoryRowBasicItem from '../../../../components/HistoryRowBasicItem';
import { formatPartialDate, sortPartialDate } from 'neuro-utils';
import { Container, Item } from '../../../../components/Grid';
import colors from '../../../../config/theme/colors';
import HistoryRowSubHeader from '../../../../components/HistoryRowSubHeader';
import HistoryRowVerticalItem from '../../../../components/HistoryRowVerticalItem';
import { getDescription } from '../../../../utility/randomUtil';
import PlatformConditional from '../../../../components/PlatformConditional';
import { isNil } from 'ramda';
import HistoryRowControls from 'Components/HistoryRowControls';

const StyledRow = ({ title, value }: { title: string | JSX.Element; value?: any }): JSX.Element => (
  <HistoryRowVerticalItem header={title} value={value ?? '-'} />
);

const fm = (id: string, values?: { [key: string]: string | number | null }): JSX.Element => (
  <FormattedMessage id={id} values={values || undefined} />
);

const listOfContracturesOfJoints = [
  'kneeFlexion',
  'hipAdductor',
  'anklePlantarFlexion',
  'ITBConctracture',
  'shoulderProtraction',
  'elbowFlexion',
  'neckRotation',
  'neckLateralFlexion',
  'fingerConctracture',
];

const ComorbidityHistoryRowData = ({ d, diagnosis, startEdit }: IOwnProps): JSX.Element => {
  const [icd10description, setIcd10description] = React.useState<string | undefined>(undefined);

  React.useEffect(() => {
    let isMounted = true;
    if (d.classification?.icd10code) {
      getDescription(d.classification.icd10code)
        .then((r) => {
          if (isMounted) setIcd10description(r);
        })
        .catch(() => {
          return;
        });
    }
    return (): void => {
      isMounted = false;
    };
  }, [d.classification?.icd10code]);

  return (
    <React.Fragment>
      <Container alignItems="baseline">
        <PlatformConditional platform={['ms', 'epilepsy']}>
          <React.Fragment>
            <Item xs={!isNil(d?.otherComorbidity) ? 3 : true}>
              <HistoryRowBasicItem
                header={fm('comorbidity.ICD-10')}
                value={
                  <div style={{ fontWeight: 600 }}>
                    {d?.classification && d.classification?.icd10code
                      ? `${d.classification?.icd10code}${icd10description ? ' - ' + icd10description : ''}`
                      : '-'}
                  </div>
                }
              />
            </Item>
            {!isNil(d?.otherComorbidity) && (
              <Item xs={3}>
                <HistoryRowBasicItem
                  header={fm('comorbidity.otherComorbidity')}
                  value={
                    <div style={{ fontWeight: 600 }}>
                      {d?.otherComorbidity && d?.otherComorbidity.length > 0 ? d?.otherComorbidity : '-'}
                    </div>
                  }
                />
              </Item>
            )}
          </React.Fragment>
        </PlatformConditional>
        <PlatformConditional platform={['sma', 'dmd']}>
          <React.Fragment>
            <Item xs={3}>
              <HistoryRowBasicItem
                header={fm('comorbidity.ICD-10')}
                value={
                  <div style={{ fontWeight: 600 }}>
                    {d?.classification && d.classification?.icd10code
                      ? `${d.classification?.icd10code}${icd10description ? ' - ' + icd10description : ''}`
                      : '-'}
                  </div>
                }
              />
            </Item>
            <Item xs={6}>
              <HistoryRowBasicItem
                header={fm('comorbidity.adverseEffectFromMedication')}
                value={
                  d?.isMedicationAdverseEffect === true
                    ? d?.medication ?? fm('general.yes')
                    : d?.isMedicationAdverseEffect === false
                      ? fm('general.no')
                      : '-'
                }
              />
            </Item>
          </React.Fragment>
        </PlatformConditional>
        <PlatformConditional platform={['sleepApnea']}>
          <React.Fragment>
            <Item xs={3}>
              <HistoryRowBasicItem
                header={fm('comorbidity.ICD-10')}
                value={
                  <div style={{ fontWeight: 600 }}>
                    {d?.classification && d.classification?.icd10code
                      ? `${d.classification?.icd10code}${icd10description ? ' - ' + icd10description : ''}`
                      : '-'}
                  </div>
                }
              />
            </Item>
            {diagnosis.includes('J96.1') || diagnosis.includes('J96.9') ? (
              <Item xs={6}>
                <HistoryRowBasicItem
                  header={fm('comorbidity.sicknessEffectOnRespiratoryFailure')}
                  value={
                    d.sicknessEffectOnRespiratoryFailure
                      ? fm(
                          `comorbidity.opts.sicknessEffectOnRespiratoryFailure.${d.sicknessEffectOnRespiratoryFailure}`,
                        )
                      : '-'
                  }
                />
              </Item>
            ) : undefined}
          </React.Fragment>
        </PlatformConditional>
        <PlatformConditional platform="ninmt">
          <React.Fragment>
            <Item xs={3}>{d?.date ? `${formatPartialDate(d.date)}` : '-'}</Item>
            <Item xs={6}>
              {d?.classification && d.classification?.icd10code
                ? `${d.classification?.icd10code}${icd10description ? ' - ' + icd10description : ''}`
                : '-'}
            </Item>
            <Item xs={3}>{startEdit ? <HistoryRowControls document={d} startEdit={startEdit} /> : null}</Item>
          </React.Fragment>
        </PlatformConditional>
      </Container>
      {d?.classification && (
        <React.Fragment>
          {['M41', 'M41.4', 'M40', 'M40.0', 'M40.1', 'M40.2'].includes(d.classification?.icd10code ?? '') && (
            <React.Fragment>
              {Array.isArray(d?.corsetTreatments) && d.corsetTreatments?.length > 0 && (
                <React.Fragment>
                  <HistoryRowSubHeader header={<FormattedMessage id="comorbidity.corsetTreatments" />} />
                  <Container style={{ color: colors.tertiaryText }}>
                    <Item xs={3}>
                      <FormattedMessage id="comorbidity.active" />
                    </Item>
                    <Item xs={9}>
                      <FormattedMessage id="comorbidity.corsetType" />
                    </Item>
                  </Container>
                  {d.corsetTreatments
                    ?.sort((n1, n2) => sortPartialDate(n1.startDate, n2.startDate))
                    .reverse()
                    .map((c: ICorsetTreatment, i: number) => (
                      <Container key={i} style={{ margin: '1rem 0 0 0' }}>
                        <Item xs={3} style={{ fontWeight: 600 }}>
                          {c?.endDate && c.endDate?.[0] && c.endDate?.[0] !== null
                            ? `${formatPartialDate(c?.startDate)} - ${formatPartialDate(c?.endDate)}`
                            : fm('comorbidity.fromDate', { date: formatPartialDate(c?.startDate) })}
                        </Item>
                        <Item xs={2}>{c?.corsetType ? fm(`comorbidity.opts.corsetType.${c?.corsetType}`) : '-'}</Item>
                      </Container>
                    ))}
                </React.Fragment>
              )}
              {Array.isArray(d?.backSurgeries) && d.backSurgeries?.length > 0 && (
                <React.Fragment>
                  <HistoryRowSubHeader header={<FormattedMessage id="comorbidity.backSurgeries" />} />
                  <Container style={{ color: colors.tertiaryText }}>
                    <Item xs={3}>
                      <FormattedMessage id="general.date" />
                    </Item>
                    <Item xs={2}>
                      <FormattedMessage id="comorbidity.surgeryTechnique" />
                    </Item>
                    <Item xs={2}>
                      <FormattedMessage id="comorbidity.instrumentation" />
                    </Item>
                    <Item xs={3}>
                      <FormattedMessage id="comorbidity.additionalInformation" />
                    </Item>
                    <Item xs={2}>
                      <FormattedMessage id="comorbidity.surgeryLevel" />
                    </Item>
                  </Container>
                  {d.backSurgeries
                    ?.sort((n1, n2) => sortPartialDate(n1.date, n2.date))
                    .reverse()
                    .map((b: IBackSurgery, i: number) => (
                      <Container key={i} style={{ margin: '1rem 0 0 0' }}>
                        <Item xs={3} style={{ fontWeight: 600 }}>
                          {formatPartialDate(b?.date)}
                        </Item>
                        <Item xs={2}>
                          {b?.surgeryTechnique ? fm(`comorbidity.opts.surgeryTechnique.${b?.surgeryTechnique}`) : '-'}
                        </Item>
                        <Item xs={2}>
                          {b?.surgeryTechnique === 'expandableBackInstrumentation'
                            ? b?.instrumentation
                              ? fm(`comorbidity.opts.instrumentation.${b?.instrumentation}`)
                              : '-'
                            : undefined}
                        </Item>
                        <Item xs={3} style={{ wordBreak: 'break-word' }}>
                          <div style={{ marginRight: '1rem' }}>
                            {b?.surgeryTechnique === 'expandableBackInstrumentation' && b?.instrumentation === 'other'
                              ? b?.instrumentationAdditionalInformation ?? '-'
                              : undefined}
                          </div>
                        </Item>
                        <Item xs={2} style={{ wordBreak: 'break-word' }}>
                          {b?.surgeryLevel ?? '-'}
                        </Item>
                      </Container>
                    ))}
                </React.Fragment>
              )}
            </React.Fragment>
          )}
          {d.classification?.icd10code === 'Q65' && Array.isArray(d?.hipSurgeries) && d.hipSurgeries?.length > 0 && (
            <React.Fragment>
              <Container alignItems="center" style={{ marginTop: '4rem' }}>
                <Item xs={9}>
                  <HistoryRowSubHeader header={<FormattedMessage id="comorbidity.hipSurgeries" />} />
                </Item>
              </Container>
              <Container style={{ color: colors.tertiaryText }}>
                <Item xs={3}>
                  <FormattedMessage id="general.date" />
                </Item>
                <Item xs={9}>
                  <FormattedMessage id="comorbidity.surgeryType" />
                </Item>
              </Container>
              {d.hipSurgeries
                ?.sort((n1, n2) => sortPartialDate(n1.date, n2.date))
                .reverse()
                .map((h: IHipSurgery, i: number) => (
                  <Container key={i} style={{ margin: '1rem 0 0 0' }}>
                    <Item xs={3} style={{ fontWeight: 600 }}>
                      {formatPartialDate(h?.date)}
                    </Item>
                    <Item xs={9}>{h?.surgeryType ? fm(`comorbidity.opts.surgeryType.${h?.surgeryType}`) : '-'}</Item>
                  </Container>
                ))}
            </React.Fragment>
          )}
          {d.classification?.icd10code === 'M24.5' && (
            <React.Fragment>
              <HistoryRowSubHeader header={<FormattedMessage id="comorbidity.contracturesOfJoints" />} />

              {listOfContracturesOfJoints.map((contracture) => (
                <StyledRow
                  key={contracture}
                  title={fm(`comorbidity.${contracture}`)}
                  value={
                    (d as { [key: string]: any })[contracture]
                      ? fm(`comorbidity.opts.${(d as { [key: string]: any })[contracture]}`)
                      : '-'
                  }
                />
              ))}
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
interface IOwnProps {
  d: IComorbidity;
  diagnosis: string[];
  startEdit?: (document: Record<string, unknown>) => (e: React.MouseEvent) => void;
}

export default ComorbidityHistoryRowData;
