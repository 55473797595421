import { omit } from 'ramda';
import * as React from 'react';
import { useAppDispatch as useDispatch, useAppSelector as useSelector } from 'Store/index';

import { CensoredInfo } from '../../../components/Censorable/components/CensoredInfo';
import DocumentCreationButton from '../../../components/DocumentCreationButton';
import DocumentHeader from '../../../components/DocumentHeader';
import DocumentWrapper from '../../../components/DocumentWrapper';
import HistoryTabs from '../../../components/HistoryTabs';
import PlatformCapabilities from '../../../config/capabilities';
import { ICapabilityContextProps, withCapabilities } from '../../../containers/CapabilityHandler';

import { RootState } from '../../../store';
import { assertCapabilities } from '../../../store/index';

import ProfileForm from './Form';
import AppointmentList from './History/Appointments';
import { actions } from '../../../store/patient/actions';
import Grants from './History/Grants';
import Toolbar from 'Components/Toolbar';
import PatientTransfer from './History/PatientTransfer';
import { useIntl } from 'react-intl';

const saveProfileData = (data: Partial<IPatientUI>, dispatch: (e: any) => any): void => {
  dispatch(actions.changePatientData(data));
};

const saveExitus = (data: TExitusUI, dispatch: (e: any) => any): void => {
  dispatch(actions.changePatientExitusData(data));
};

const Profile = ({ capabilityGroups = {} }: IOwnProps): JSX.Element => {
  const shouldCensor = useSelector((s: RootState) => s.session.shouldCensor);

  const appointmentsCapability = assertCapabilities([PlatformCapabilities.APPOINTMENTS], capabilityGroups);
  const editingCapability = assertCapabilities([PlatformCapabilities.PATIENT_PROFILE_EDIT], capabilityGroups);

  const patientData = useSelector((s: { patient: IPatientStore }) => s.patient.data);

  const dispatch = useDispatch();

  const { formatMessage } = useIntl();
  const fm = (id: string) => formatMessage({ id });

  const [formValues, setFormValues] = React.useState<Partial<IPatientUI> | undefined>(undefined);
  const [exitusFormValues, setExitusFormValues] = React.useState<TExitusUI | undefined>(undefined);

  const setInitialFormData = () => {
    if (patientData) {
      setFormValues(
        omit(['isDead', 'dateOfDeath', 'reasonOfDeath', 'placeOfDeath', 'inpatientHospitalDays'], patientData),
      );
      setExitusFormValues({
        isDead: patientData.isDead,
        dateOfDeath: patientData.dateOfDeath,
        reasonOfDeath: patientData.reasonOfDeath,
        placeOfDeath: patientData.placeOfDeath,
        inpatientHospitalDays: patientData.inpatientHospitalDays,
      });
    }
  };

  React.useEffect(() => {
    setInitialFormData();
  }, [patientData]);

  // For saving profile data -->
  const [saveStatus, setSaveStatus] = React.useState<boolean>(false);
  const setSaved = (): void => setSaveStatus(true);

  React.useEffect(() => {
    if (saveStatus) {
      if (formValues) {
        saveProfileData(formValues, dispatch);
      }
      if (exitusFormValues) {
        saveExitus(exitusFormValues, dispatch);
      }
      setSaveStatus(false);
    }
  }, [saveStatus]);
  // <--

  const cancel = () => {
    setInitialFormData();
  };

  const [openTab, setOpenTab] = React.useState<number>(0);

  const [editing, setEditing] = React.useState<boolean>(false);

  return (
    <>
      <Toolbar
        current="profile"
        editing={editing ? 'editing' : undefined}
        saveDraft={() => () => {
          setSaved();
          setEditing(false);
        }}
        cancelDraft={() => () => {
          cancel();
          setEditing(false);
        }}
      />
      {!shouldCensor ? (
        <DocumentWrapper>
          <DocumentHeader
            name={'profile'}
            headerId={'profile.title'}
            editing={editing ? 'editing' : undefined}
            editButtons={
              openTab === 0 ? (
                <DocumentCreationButton
                  name="profile"
                  text={'general.edit'}
                  onClick={() => setEditing(true)}
                  disabled={!editingCapability}
                  disabledTooltip={<div>{fm('profile.noEditCapability')}</div>}
                />
              ) : undefined
            }
          />
          {!editing ? (
            <HistoryTabs indexSelectionTools={{ index: openTab, changeFunction: (index) => setOpenTab(index) }}>
              {[
                {
                  title: 'profile.title',
                  content: (
                    <ProfileForm
                      editing={editing}
                      formValues={formValues}
                      setProfileFormValues={setFormValues}
                      exitusFormValues={exitusFormValues}
                      setExitusFormValues={setExitusFormValues}
                    />
                  ),
                },
                ...(appointmentsCapability
                  ? [
                      {
                        title: 'appointments.appointments',
                        content: <AppointmentList />,
                      },
                    ]
                  : []),

                {
                  title: 'grants.title',
                  content: <Grants />,
                },
                {
                  title: 'patientTransfer.title',
                  content: <PatientTransfer />,
                },
              ]}
            </HistoryTabs>
          ) : (
            <ProfileForm
              editing={editing}
              formValues={formValues}
              setProfileFormValues={setFormValues}
              exitusFormValues={exitusFormValues}
              setExitusFormValues={setExitusFormValues}
            />
          )}
        </DocumentWrapper>
      ) : (
        <CensoredInfo />
      )}
    </>
  );
};

type IOwnProps = ICapabilityContextProps;

export default withCapabilities(Profile);
