import { omit } from 'ramda';
import { exists } from 'neuro-utils';

const allNormal = (values: { [key: string]: any }): boolean => {
  return Object.keys(values).filter((key) => {
    return values[key] && values[key] !== 0 && values[key] !== 'notDone';
  }).length > 0
    ? false
    : true;
};

const allNotDoneOrUndefined = (values: { [key: string]: any }): boolean => {
  return Object.keys(values).every((key) => !exists(values?.[key]) || values?.[key] === 'notDone');
};

const brainstemStatus = (values: { [key: string]: any }, maxValue: number): boolean => {
  return Object.keys(values).filter((key) => {
    return values[key] > maxValue;
  }).length > 0
    ? true
    : false;
};

const brainstemCalculator = (brainstemFunctions: { [key: string]: any }): number | undefined => {
  const b = brainstemFunctions;

  if (allNormal(b)) {
    if (allNotDoneOrUndefined(b)) {
      return undefined;
    }
    return 0;
  }
  if (
    b?.nystagmus > 1 ||
    b?.extraocularMovementsImpairment > 2 ||
    brainstemStatus(omit(['nystagmus', 'extraocularMovementsImpairment'], b), 1)
  ) {
    if (
      b?.nystagmus > 2 ||
      b?.extraocularMovementsImpairment > 3 ||
      brainstemStatus(omit(['nystagmus', 'extraocularMovementsImpairment'], b), 2)
    ) {
      if (
        b?.dysarthria > 3 ||
        brainstemStatus(omit(['dysarthria', 'nystagmus', 'extraocularMovementsImpairment'], b), 3)
      ) {
        if (b?.dysarthria > 4 || b?.dysphagia > 4) {
          return 5;
        }
        return 4;
      }
      return 3;
    }
    return 2;
  }
  return 1;
};

export default brainstemCalculator;
