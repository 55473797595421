import { fm } from 'Components/FormatMessage';
import HistoryRowVerticalItem from 'Components/HistoryRowVerticalItem';
import HistorySection from 'Components/HistorySection';
import { IHistoryContext, withHistoryContext } from 'Containers/FormContextHandler';
import { isEmpty, isNil } from 'ramda';
import * as React from 'react';
import { newButton } from 'Routes/Diagnosis/utils/functions';
import { getDescription } from 'Utility/randomUtil';

const IndicationForTreatmentHistory = ({
  documents = [],
  startEdit,
  treatmentPeriod,
  setTreatmentId,
}: IHistoryContext & { treatmentPeriod: ININMTTreatmentPeriod; setTreatmentId: (id: string) => void }): JSX.Element => {
  const [icd10description, setIcd10description] = React.useState<string | undefined>(undefined);
  const treatmentId = treatmentPeriod?._id;

  const indicationForTreatment: IIndicationForTreatment = documents.find(
    (d) => d._type === 'indicationForTreatment' && d.treatmentPeriodId === treatmentId,
  );

  React.useEffect(() => {
    let isMounted = true;
    if (indicationForTreatment?.painICD10?.icd10code) {
      getDescription(indicationForTreatment.painICD10?.icd10code)
        .then((r) => {
          if (isMounted) setIcd10description(r);
        })
        .catch(() => {
          return;
        });
    }
    return (): void => {
      isMounted = false;
    };
  }, [indicationForTreatment?.painICD10?.icd10code]);

  return (
    <HistorySection
      headerText={<span style={{ fontSize: '2.2rem' }}>{fm('diagnosis.ninmt.indicationForTreatment.title')}</span>}
      hasHistoryRows={false}
      newButton={
        startEdit
          ? newButton(
              'indicationForTreatment',
              (e: React.MouseEvent): void => {
                setTreatmentId(treatmentId);
                startEdit(indicationForTreatment ?? {}, 'indicationForTreatment')(e);
              },
              'general.edit',
              undefined,
              true,
              !isNil(((indicationForTreatment ?? {}) as IControlProps)._lockedFor) ||
                treatmentPeriod._editing ||
                !treatmentPeriod?.date,
              isNil(((indicationForTreatment ?? {}) as IControlProps)._lockedFor)
                ? 'diagnosis.ninmt.treatmentPeriodDisabledTooltip'
                : undefined,
              undefined,
              indicationForTreatment,
            )
          : undefined
      }
    >
      <HistoryRowVerticalItem
        header={''}
        headerWidth={3}
        value={
          indicationForTreatment && indicationForTreatment.indication && !isEmpty(indicationForTreatment.indication)
            ? indicationForTreatment.indication?.map((i) => {
                if (i === 'pain') {
                  return (
                    <>
                      <span>{fm(`diagnosis.ninmt.indicationForTreatment.opts.${i}`)}</span>
                      <span style={{ fontWeight: 400 }}>
                        {indicationForTreatment?.painICD10 && indicationForTreatment.painICD10.icd10code
                          ? ` ${indicationForTreatment.painICD10.icd10code}${
                              icd10description ? ' - ' + icd10description : ''
                            }`
                          : undefined}
                      </span>
                      {indicationForTreatment?.painSpecification ? (
                        <span style={{ fontWeight: 400 }}>
                          {' - '}
                          {fm(
                            `diagnosis.ninmt.indicationForTreatment.opts.${indicationForTreatment?.painSpecification}`,
                          )}
                        </span>
                      ) : undefined}
                      {indicationForTreatment.neuropathicPainFinding ||
                      indicationForTreatment.neuropathicPainFindingCertainty ? (
                        <ul style={{ margin: 0, paddingLeft: '3rem' }}>
                          {indicationForTreatment.neuropathicPainFinding &&
                            Array.isArray(indicationForTreatment.neuropathicPainFinding) &&
                            indicationForTreatment.neuropathicPainFinding.length > 0 && (
                              <li style={{ fontWeight: 400 }}>
                                {fm('diagnosis.ninmt.indicationForTreatment.neuropathicFinding')}:{' '}
                                {['abnormalQST', 'abnormalENMG', 'abnormalSkinBiopsy', 'abnormalEvokedResponse']
                                  .filter((f) =>
                                    (indicationForTreatment.neuropathicPainFinding as string[]).includes(f),
                                  )
                                  .map((f, i, arr) => (
                                    <span key={i}>
                                      {fm(`diagnosis.ninmt.indicationForTreatment.opts.${f}`)}
                                      {arr.length > 1 && i < arr.length - 1 && ', '}
                                    </span>
                                  ))}
                              </li>
                            )}
                          {indicationForTreatment.neuropathicPainFindingCertainty && (
                            <li style={{ fontWeight: 400 }}>
                              {fm('diagnosis.ninmt.indicationForTreatment.neuropathicPainDiagnosisCertainty')}:{' '}
                              {fm(
                                `diagnosis.ninmt.indicationForTreatment.opts.${indicationForTreatment.neuropathicPainFindingCertainty}`,
                              )}
                            </li>
                          )}
                        </ul>
                      ) : undefined}
                    </>
                  );
                }
                return fm(`diagnosis.ninmt.indicationForTreatment.opts.${i}`);
              })
            : undefined
        }
        noBullet
        noDash
      />
    </HistorySection>
  );
};

export default withHistoryContext(IndicationForTreatmentHistory);
