import * as React from 'react';

import HistorySection from '../../../../../../components/HistorySection';
import HistoryRowVerticalItem from '../../../../../../components/HistoryRowVerticalItem';

import { formatPartialDate } from 'neuro-utils';
import { newButton } from '../../../../utils/functions';
import { isNil } from 'ramda';
import { IHistoryContext, withHistoryContext } from '../../../../../../containers/FormContextHandler';

const MsSymptoms = ({ documents, startEdit, fm = (): string => '' }: IHistoryContext): JSX.Element => {
  const msSymptoms: IMsSymptoms = documents?.filter((d) => d._type === 'msSymptoms')[0] as IMsSymptoms;

  return (
    <HistorySection
      headerText={fm('diagnosis.msEarlySymption')}
      hasHistoryRows={false}
      newButton={
        startEdit
          ? newButton(
              'msSymptoms',
              startEdit(msSymptoms ?? {}, 'msSymptoms'),
              'general.edit',
              undefined,
              true,
              !isNil(((msSymptoms ?? {}) as IControlProps)._lockedFor),
              undefined,
              undefined,
              msSymptoms,
            )
          : undefined
      }
    >
      <React.Fragment>
        <HistoryRowVerticalItem
          header={fm('diagnosis.symptomOnsetDate')}
          value={
            msSymptoms?.symptomOnsetDate ? (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {formatPartialDate(msSymptoms?.symptomOnsetDate)}
              </div>
            ) : (
              ' - '
            )
          }
        />

        <HistoryRowVerticalItem
          header={fm('diagnosis.msSymptom.title')}
          value={
            msSymptoms?.msSymptoms?.length
              ? msSymptoms?.msSymptoms.map((item: string) => fm('diagnosis.msSymptom.opts.' + item))
              : ' - '
          }
        />

        <HistoryRowVerticalItem
          header={fm('diagnosis.msSymptomLevel.title')}
          value={
            msSymptoms?.msSymptomLevel?.length
              ? msSymptoms?.msSymptomLevel.map((item: string) => fm('diagnosis.msSymptomLevel.opts.' + item))
              : ' - '
          }
        />

        <HistoryRowVerticalItem
          header={fm('diagnosis.msOriginOfInformation.title')}
          value={
            msSymptoms?.msOriginOfInformation
              ? fm('diagnosis.msOriginOfInformation.opts.' + msSymptoms?.msOriginOfInformation)
              : ' - '
          }
        />

        <HistoryRowVerticalItem
          header={fm('diagnosis.msWasCortisoneGiven')}
          value={
            msSymptoms?.msWasCortisoneGiven
              ? fm('diagnosis.opts.yesNoUnknown.' + msSymptoms?.msWasCortisoneGiven)
              : ' - '
          }
        />

        {msSymptoms?.msWasCortisoneGiven === 'yes' && (
          <HistoryRowVerticalItem
            header={fm('diagnosis.msCortisoneMedicationRecorded')}
            value={
              msSymptoms?.msCortisoneMedicationRecorded
                ? fm('diagnosis.opts.yesNoUnknown.' + msSymptoms?.msCortisoneMedicationRecorded)
                : '-'
            }
          />
        )}

        <HistoryRowVerticalItem
          header={fm('diagnosis.msWasPlasmaGiven')}
          value={
            msSymptoms?.msWasPlasmaGiven ? fm('diagnosis.opts.yesNoUnknown.' + msSymptoms?.msWasPlasmaGiven) : ' - '
          }
        />
      </React.Fragment>
    </HistorySection>
  );
};

export default withHistoryContext(MsSymptoms);
