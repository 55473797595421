import { path } from 'ramda';
import * as React from 'react';

import { TDocument } from '../../utils';
import NPIForm from './NPI';
import FBIMODForm from './FBIMOD';
import BNSQForm from './BNSQ';
import ADLForm from './ADL';
import IADLForm from './IADL';

const DiagnosticSurveyForm = ({ document }: IOwnProps): JSX.Element | null => {
  if (!document) return null;
  return path(['_type'], document) === 'npi' ? (
    <NPIForm />
  ) : path(['_type'], document) === 'fbimod' ? (
    <FBIMODForm />
  ) : path(['_type'], document) === 'bnsq' ? (
    <BNSQForm />
  ) : path(['_type'], document) === 'adl' ? (
    <ADLForm />
  ) : path(['_type'], document) === 'iadl' ? (
    <IADLForm />
  ) : null;
};

interface IOwnProps {
  document?: TDocument;
}

export default DiagnosticSurveyForm;
