import { fm } from 'Components/FormatMessage';
import { Container, Item } from 'Components/Grid';
import HistoryRowVerticalItem from 'Components/HistoryRowVerticalItem';
import HistorySection from 'Components/HistorySection';
import { IHistoryContext, withHistoryContext } from 'Containers/FormContextHandler';
import { formatPartialDate } from 'neuro-utils';
import { isNil } from 'ramda';
import * as React from 'react';
import { newButton } from 'Routes/Diagnosis/utils/functions';

const TreatmentPeriod = ({
  startEdit,
  treatmentPeriod,
}: IHistoryContext & { treatmentPeriod?: ININMTTreatmentPeriod }): JSX.Element => {
  return (
    <HistorySection hasHistoryRows={false} mode="noBar">
      <HistoryRowVerticalItem
        header={fm('diagnosis.ninmt.treatmentDecisionDate')}
        headerWidth={3}
        value={
          <Container>
            <Item xs={2}>
              {treatmentPeriod && treatmentPeriod.date ? formatPartialDate(treatmentPeriod.date) : '-'}
            </Item>
            <Item xs={true}>
              {startEdit
                ? newButton(
                    'ninmtTreatmentPeriod',
                    startEdit(treatmentPeriod ?? {}, 'ninmtTreatmentPeriod'),
                    'general.edit',
                    7,
                    undefined,
                    !isNil(((treatmentPeriod ?? {}) as IControlProps)._lockedFor),
                    undefined,
                    true,
                    treatmentPeriod,
                  )
                : undefined}
            </Item>
          </Container>
        }
      />
    </HistorySection>
  );
};

export default withHistoryContext(TreatmentPeriod);
