import * as React from 'react';
import UHDRSFunctionalAssessmentHistory from './HistoryRowData/UHDRSFunctionalAssessment';
import UHDRSIndependenceScaleHistory from './HistoryRowData/UHDRSIndependenceScale';
import UHDRSFunctionalCapacityHistory from './HistoryRowData/UHDRSFunctionalCapacity';
import ModifiedRankinScaleHistory from './HistoryRowData/ModifiedRankinScale';
import ModifiedChildrensGlobalAssessmentScaleHistory from './HistoryRowData/ModifiedChildrensGlobalAssessmentScale';
import { filterDocs, getHeaderId, TDocument } from '../utils';
import FormEditingHandler from '../../../containers/FormEditingHandler';
import DocumentWrapper from '../../../components/DocumentWrapper';
import DocumentHeader from '../../../components/DocumentHeader';
import Form from './Form';
import { find } from 'ramda';
import PlatformConditional from '../../../components/PlatformConditional';

const FunctionalPerformanceHistory = (
  documents: Array<TDocument>,
  startEdit: (document: TAnyObject) => (e: React.MouseEvent<Element, MouseEvent>) => void,
  view?: IView,
): JSX.Element => {
  const UHDRSFunctionalAssessmentDocs = filterDocs('uhdrsFunctionalAssessment', documents);
  const UHDRSIndependenceScaleDocs = filterDocs('uhdrsIndependenceScale', documents);
  const UHDRSFunctionalCapacityDocs = filterDocs('uhdrsFunctionalCapacity', documents);
  const modifiedRankinScaleDocs = filterDocs('modifiedRankinScale', documents) as IModifiedRankinScale[];
  const modifiedChildrensGlobalAssessmentScaleDocs = filterDocs(
    'modifiedChildrensGlobalAssessmentScale',
    documents,
  ) as IModifiedChildrensGlobalAssessmentScale[];
  return (
    <React.Fragment>
      <PlatformConditional platform="huntington">
        <React.Fragment>
          <UHDRSFunctionalAssessmentHistory
            documents={UHDRSFunctionalAssessmentDocs}
            startEdit={startEdit}
            view={view}
          />
          <UHDRSIndependenceScaleHistory documents={UHDRSIndependenceScaleDocs} startEdit={startEdit} view={view} />
          <UHDRSFunctionalCapacityHistory documents={UHDRSFunctionalCapacityDocs} startEdit={startEdit} view={view} />
        </React.Fragment>
      </PlatformConditional>
      <PlatformConditional platform="epilepsy">
        <React.Fragment>
          <ModifiedRankinScaleHistory documents={modifiedRankinScaleDocs} startEdit={startEdit} view={view} />
          <ModifiedChildrensGlobalAssessmentScaleHistory
            documents={modifiedChildrensGlobalAssessmentScaleDocs}
            startEdit={startEdit}
            view={view}
          />
        </React.Fragment>
      </PlatformConditional>
    </React.Fragment>
  );
};

const FunctionalPerformance = ({ documents }: IOwnProps): JSX.Element => (
  <FormEditingHandler name="functionalPerformance" documents={documents}>
    {(editing, startEdit, formData, view): JSX.Element => (
      <DocumentWrapper>
        <DocumentHeader
          name="functionalPerformance"
          headerId={getHeaderId(documents, editing, view?.viewing)}
          editing={editing}
          editButtons={<div />}
        />
        {editing ? (
          <Form
            formData={formData}
            document={documents && (find((d: TDocument) => d._id === editing, documents) as TDocument)}
          />
        ) : null}
        {view?.viewing ? (
          <Form
            formData={{ document: view.document, onChange: (): string => '' }}
            document={documents && (find((d: TDocument) => d._id === view?.viewing, documents) as TDocument)}
            viewing={!!view.viewing}
          />
        ) : null}
        {!editing && !view?.viewing ? FunctionalPerformanceHistory(documents, startEdit, view) : undefined}
      </DocumentWrapper>
    )}
  </FormEditingHandler>
);

interface IOwnProps {
  documents: Array<TDocument>;
}

export default FunctionalPerformance;
