import { calculateDaysDifference, exists, isPartialDate } from 'neuro-utils';
import { isEmpty, isNil } from 'ramda';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import FormRow from '../../../../../components/FormRow';
import InputHandler from '../../../../../components/InputHandler';
import Unit from '../../../../../components/Unit';
import { IFormContext, withFormContext } from '../../../../../containers/FormContextHandler';
import {
  checkIfAcuteTreatmentStart,
  checkIfCurrentDocumentIsTheFirstTreatment,
  getDateOfReferral,
  showDelay,
} from '../../../utils';
import { EndForm } from '../components';
import Settings from './Settings';
import TreatmentResponses from './TreatmentResponses';

const locPath = 'treatment.oxygenTherapy.';

const optFormatter = (o: string | number): string | JSX.Element => <FormattedMessage id={locPath + 'opts.' + o} />;

const OxygenTherapy = ({
  formData,
  view,
  editing,
  documents = [],
}: IFormContext<IOxygenTherapy, IOxygenTherapy>): JSX.Element => {
  const referralDate = getDateOfReferral(documents, 'respiratoryFirstVisit');
  const isAcute = checkIfAcuteTreatmentStart(documents, 'respiratoryFirstVisit');
  const isFirstDoc = checkIfCurrentDocumentIsTheFirstTreatment(documents, view, editing);

  const delayFromSuspicionToTreatmentStart = calculateDaysDifference(referralDate, formData.document.date);

  const disabledTherapyTypes = (): Array<string> | undefined => {
    const activeDocs = documents.filter((d) => d._id !== editing && !isPartialDate(d.endDate));

    if (activeDocs.some((d) => (d.oxygenTherapyType ?? '').includes('oxygenConcentrator'))) {
      return ['oxygenConcentratorTherapeutic', 'oxygenConcentratorPalliative'];
    }
    if (activeDocs.some((d) => d.oxygenTherapyType === 'stressOxygen')) {
      return ['stressOxygen'];
    }
    return undefined;
  };

  return (
    <React.Fragment>
      <FormRow title="treatment.date">
        <InputHandler
          type="PartialDate"
          editing={!view?.viewing}
          name="date"
          formData={formData}
          dateDefault="now"
          isNotCancellable={true}
          dateHook={{ dateHookCeiling: formData.document.endDate }}
        />
      </FormRow>
      <FormRow title={locPath + 'delay'} condition={showDelay(isAcute, isFirstDoc)}>
        <Unit unit={<FormattedMessage id="treatment.daysPlaceholder" />} fontWeight={view?.viewing ? 'bold' : 'normal'}>
          {exists(delayFromSuspicionToTreatmentStart) ? (delayFromSuspicionToTreatmentStart as number) : '-'}
        </Unit>
      </FormRow>
      <FormRow title={locPath + 'treatmentStartLocation'}>
        <InputHandler
          type="Radio"
          editing={!view?.viewing}
          name="treatmentStartLocation"
          formData={formData}
          options={['intensiveCareUnit', 'inpatientWard', 'outpatientClinic', 'primaryHealthCare']}
          optionFormatter={optFormatter}
        />
      </FormRow>
      <FormRow title={locPath + 'treatmentStart'}>
        <InputHandler
          type="Radio"
          editing={!view?.viewing}
          name="treatmentStart"
          formData={formData}
          options={['onCall', 'planned']}
          optionFormatter={optFormatter}
        />
      </FormRow>
      <FormRow title={locPath + 'oxygenTherapyType'}>
        <InputHandler
          type="Radio"
          editing={!view?.viewing}
          name="oxygenTherapyType"
          formData={formData}
          options={['oxygenConcentratorTherapeutic', 'oxygenConcentratorPalliative', 'stressOxygen']}
          optionFormatter={optFormatter}
          disabledOptions={disabledTherapyTypes()}
          dependentFieldsList={(value) =>
            value === 'stressOxygen' &&
            !isNil(formData.document.treatmentResponses) &&
            !isEmpty(formData.document.treatmentResponses)
              ? ['treatmentResponses']
              : []
          }
          dependentFieldsRemovalWarning={true}
        />
      </FormRow>
      <Settings />
      {formData.document.oxygenTherapyType !== 'stressOxygen' && <TreatmentResponses />}
      <EndForm formData={formData} edit={!view?.viewing} type="oxygenTherapy" />
    </React.Fragment>
  );
};

export default withFormContext(OxygenTherapy);
