import * as React from 'react';
import FormEditingHandler from '../../../containers/FormEditingHandler';
import { filterDocs, TDocument } from '../utils';
import { find } from 'ramda';

import Form from './Form';

import SleepPolygraphyHistory from './HistoryRowData/SleepPolygraphy';
import SleepStudyHistory from './HistoryRowData/SleepStudy';
import SpirometryHistory from './HistoryRowData/Spirometry';
import NightOximetryHistory from './HistoryRowData/NightOximetry';
import CarbonDioxideMeasurementHistory from './HistoryRowData/CarbonDioxideMeasurement';

import DocumentWrapper from '../../../components/DocumentWrapper';
import DocumentHeader from '../../../components/DocumentHeader';
import { getHeaderId } from '../utils';
import { FormContextProvider } from '../../../containers/FormContextHandler';
import PlatformConditional from 'Components/PlatformConditional';
import DiagnosticExaminationHistory from './HistoryRowData/DiagnosticExamination';
import { useSelector } from 'react-redux';

const ClpAndCnpTestsHistory = (
  documents: Array<TDocument>,
  startEdit: (document: TAnyObject) => (e: React.MouseEvent<Element, MouseEvent>) => void,
  view?: IView,
  setDiagnosticExamKey?: React.Dispatch<React.SetStateAction<TDiagnosticExamKeys | undefined>>,
): JSX.Element => {
  const SleepPolygraphyDocs = filterDocs('sleepPolygraphy', documents);
  const SleepStudyDocs = filterDocs('sleepStudy', documents);
  const SpirometryDocs = filterDocs('spirometry', documents);
  const NightOximetryDocs = filterDocs('nightOximetry', documents);
  const CarbonDioxideMeasurementDocs = filterDocs('carbonDioxideMeasurement', documents);
  const respiratoryFailureDiagnosis = filterDocs('diagnosis', documents).filter(
    (doc: IDiagnosis) =>
      doc.diagnosis === 'J96.1' || doc.diagnosis === 'J96.9' || doc.diagnosis === 'respiratoryFailureSuspicion',
  );
  const diagnosticExaminationDocs = filterDocs('diagnosticExamination', documents);

  return (
    <>
      <PlatformConditional platform={'sleepApnea'}>
        <SleepPolygraphyHistory
          documents={SleepPolygraphyDocs as ISleepPolygraphy[]}
          startEdit={startEdit}
          view={view}
        />
        <SleepStudyHistory documents={SleepStudyDocs as ISleepStudy[]} startEdit={startEdit} view={view} />
        <SpirometryHistory documents={SpirometryDocs as ISpirometry[]} startEdit={startEdit} view={view} />
        <NightOximetryHistory documents={NightOximetryDocs as INightOximetry[]} startEdit={startEdit} view={view} />
        {respiratoryFailureDiagnosis && respiratoryFailureDiagnosis.length > 0 ? (
          <CarbonDioxideMeasurementHistory
            documents={CarbonDioxideMeasurementDocs as ICarbonDioxideMeasurement[]}
            startEdit={startEdit}
            view={view}
          />
        ) : (
          <></>
        )}
      </PlatformConditional>
      <PlatformConditional platform={'mgravis'}>
        <DiagnosticExaminationHistory
          documents={diagnosticExaminationDocs as IDiagnosticExamination[]}
          startEdit={startEdit}
          setDiagnosticExamCode={setDiagnosticExamKey}
        />
      </PlatformConditional>
    </>
  );
};

const ClpAndCnpTests = ({ documents }: IOwnProps): JSX.Element => {
  const [diagnosticExamKey, setDiagnosticExamKey] = React.useState<TDiagnosticExamKeys | undefined>(undefined);
  const platform = useSelector((s: IState) => s.session.platforms?.selected);

  return (
    <FormEditingHandler name="clpAndCnpTests" documents={documents}>
      {(editing, startEdit, formData, view): JSX.Element => (
        <DocumentWrapper>
          <DocumentHeader
            name="clpAndCnpTests"
            headerId={getHeaderId(documents, editing, view?.viewing, diagnosticExamKey, platform)}
            editing={editing}
            editButtons={<div />}
          />
          {editing ? (
            <FormContextProvider context={{ formData, documents }}>
              <Form
                formData={formData}
                document={documents && (find((d: TDocument) => d._id === editing, documents) as TDocument)}
                documents={documents}
                diagnosticExamKey={diagnosticExamKey}
              />
            </FormContextProvider>
          ) : null}
          {view?.viewing ? (
            <Form
              formData={formData}
              document={documents && (find((d: TDocument) => d._id === view?.viewing, documents) as TDocument)}
              viewing={!!view.viewing}
              documents={documents}
              diagnosticExamKey={diagnosticExamKey}
            />
          ) : null}
          {!editing && !view?.viewing
            ? ClpAndCnpTestsHistory(documents, startEdit, view, setDiagnosticExamKey)
            : undefined}
        </DocumentWrapper>
      )}
    </FormEditingHandler>
  );
};

interface IOwnProps {
  documents: Array<TDocument>;
}

export default ClpAndCnpTests;
