import { path } from 'ramda';
import * as React from 'react';
import { TDocument } from '../../utils';
import UHDRSForm from './UHDRS';

const MotorFuncHuntForm = ({ formData, document, viewing = false, editing }: IOwnProps): JSX.Element | null =>
  path(['_type'], document) === 'uhdrsMotorRatingScale' ? (
    <UHDRSForm formData={formData} viewing={viewing} editing={editing} />
  ) : null;

interface IOwnProps {
  formData: IFormData<TDocument>;
  document: TDocument;
  viewing?: boolean;
  editing?: any;
}

export default MotorFuncHuntForm;
