import * as React from 'react';
import { connect, useSelector } from 'react-redux';

import Form from './Form';
import HistoryRowDataComorbidity from './HistoryRowData/Comorbidity';
import HistoryRowDataHospitalization from './HistoryRowData/Hospitalization';
import HistoryRowDataPregnancy from './HistoryRowData/Pregnancy';
import HistoryRowDataContraception from './HistoryRowData/Contraception';
import HistoryRowDataSickLeave from './HistoryRowData/SickLeave';

import DocumentHeader from '../../../components/DocumentHeader';
import DocumentWrapper from '../../../components/DocumentWrapper';
import HistoryRow from '../../../components/HistoryRow';
import FormEditingHandler from '../../../containers/FormEditingHandler';

import HistorySection from '../../../components/HistorySection';
import { FormattedMessage } from 'react-intl';
import {
  getDocumentsByType,
  docType,
  getMedicationNames,
  TDocument,
  stateToHistoryProps,
  getDiagnosisCodes,
} from '../utils';
import HistoryRowControls from '../../../components/HistoryRowControls';
import { getSectionTitle } from '../../../utility/randomUtil';
import PlatformConditional from '../../../components/PlatformConditional';
import { find } from 'ramda';
import { ssnGender, formatPartialDate, sortPartialDate, findLatest, isPartialDate } from 'neuro-utils';
import DocumentCreationButton from '../../../components/DocumentCreationButton';
import { isEnded } from '../../../utility/isEnded';
import HistoryRowListing from 'Components/HistoryRowListing';
import { Item } from 'Components/Grid';
import { platforms } from '../../../config/platformSettings';
import MgravisHospitalizationHistory from './HistoryRowData/MgravisHospitalization';

interface ComorbidityHistoryProps extends Pick<IJWTBody, 'visitreason' | 'patientssn'> {
  documents: (IComorbidity | IHospitalization | IMedication)[];
  startEdit: (document: TAnyObject, name?: string | undefined) => (e: React.MouseEvent) => void;
  diagnosis: string[];
}

const _History = ({
  documents,
  startEdit,
  visitreason,
  patientssn,
  diagnosis,
}: ComorbidityHistoryProps): JSX.Element => {
  const comorbidities = getDocumentsByType(['comorbidity'], documents) as IComorbidity[];
  const hospitalizations = getDocumentsByType(['hospitalization'], documents) as IHospitalization[];
  const mgravisHospitalizations = hospitalizations.filter(
    (h) => h.hospitalizationType === 'acute' && h.classification?.icd10code === 'G70',
  );
  const pregnancies = getDocumentsByType(['pregnancy'], documents) as IPregnancy[];
  const contraceptions = getDocumentsByType(['contraception'], documents) as IContraception[];
  const sickLeaves = getDocumentsByType(['sickLeave'], documents) as ISickLeave[];

  const platform = useSelector((s: { session: ISessionStore }) => s.session.platforms?.selected);

  // conditions for restricted operations
  const isRetrospective = visitreason === 'retrospectiveDataTransfer';
  const isFemale = ssnGender(patientssn) === 'f';
  const ongoingPregnancy = findLatest<IPregnancy>(
    pregnancies,
    (pregnancy: IPregnancy) => !isPartialDate(pregnancy.endDate),
    { singleton: true }, // most recent record disregards any old records
  );
  const ongoingContraception = findLatest<IContraception>(
    contraceptions,
    (c: IContraception) => !isPartialDate(c.endDate),
    { singleton: false }, // older contraception may be ongoing even when more recent has ended
  );

  /** Extra sections for epilepsy. */
  const extraEpSections = !isFemale
    ? // none specified for males
      []
    : // sections for females
      ([
        { documents: pregnancies, type: 'pregnancy', headerText: 'pregnancies', buttonText: 'newPregnancy' },
        {
          documents: contraceptions,
          type: 'contraception',
          headerText: 'usesOfContraceptives',
          buttonText: 'newUseOfContraceptive',
        },
      ] as const);

  const disableAddNewOf = (section: 'pregnancy' | 'contraception'): boolean => {
    if (isRetrospective) return false; // always allow entering data in retrospective mode
    if (section === 'pregnancy') return !!ongoingPregnancy;
    else if (section === 'contraception') return !!ongoingContraception;
    else return false;
  };

  return (
    <React.Fragment>
      <PlatformConditional platform={platforms.filter((p) => p !== 'mgravis')}>
        <HistorySection
          headerText={
            <FormattedMessage
              id={platform && platform === 'ninmt' ? 'comorbidity.diagnoses' : 'comorbidity.comorbidities'}
            />
          }
          hasHistoryRows={true}
          newButton={
            <DocumentCreationButton
              name="comorbidity"
              text={platform && platform === 'ninmt' ? 'comorbidity.newDiagnosis' : 'comorbidity.newComorbidity'}
              onClick={startEdit({}, 'comorbidity')}
              width={15}
              height={3}
              fontSize={14}
              alternate={true}
            />
          }
        >
          {platform && platform === 'ninmt' ? (
            <React.Fragment>
              {['ninmtSymptom', 'otherDisease'].map((header) => {
                const comorbiditiesFiltered = comorbidities.filter((c) =>
                  header === 'ninmtSymptom' ? c.ninmtSymptom : !c.ninmtSymptom,
                );
                return (
                  comorbiditiesFiltered.length > 0 && (
                    <HistoryRow key={header} headerText={<FormattedMessage id={`comorbidity.${header}`} />}>
                      <HistoryRowListing
                        documents={comorbiditiesFiltered}
                        headers={
                          <React.Fragment>
                            <Item xs={3}>
                              <FormattedMessage id="comorbidity.diagnosisDate" />
                            </Item>
                            <Item xs={9}>
                              <FormattedMessage id="comorbidity.ICD-10" />
                            </Item>
                          </React.Fragment>
                        }
                        contentFormat={(d: (typeof comorbiditiesFiltered)[0]): JSX.Element => (
                          <React.Fragment>
                            <HistoryRowDataComorbidity
                              d={d}
                              diagnosis={diagnosis ? diagnosis : []}
                              startEdit={startEdit}
                            />
                          </React.Fragment>
                        )}
                      />
                    </HistoryRow>
                  )
                );
              })}
            </React.Fragment>
          ) : (
            comorbidities.map((c) => (
              <HistoryRow
                headerText={
                  c.date
                    ? formatPartialDate(c.date) + `${c.endDate ? ' - ' + formatPartialDate(c.endDate) : ''}`
                    : undefined
                }
                key={c._id}
                active={!isPartialDate(c.endDate) || !isEnded(c.endDate)}
                rowButton={<HistoryRowControls document={c} startEdit={startEdit} />}
                controlsMargin={false}
              >
                <HistoryRowDataComorbidity d={c} diagnosis={diagnosis ? diagnosis : []} />
              </HistoryRow>
            ))
          )}
        </HistorySection>
      </PlatformConditional>
      <PlatformConditional platform={['sma', 'dmd', 'mgravis']}>
        <HistorySection
          headerText={<FormattedMessage id="comorbidity.hospitalizations" />}
          hasHistoryRows={true}
          newButton={
            <DocumentCreationButton
              name="hospitalization"
              text={'comorbidity.newHospitalization'}
              onClick={startEdit({}, 'hospitalization')}
              width={15}
              height={3}
              fontSize={14}
              alternate={true}
            />
          }
        >
          {platform === 'mgravis' ? (
            <MgravisHospitalizationHistory documents={mgravisHospitalizations} startEdit={startEdit} />
          ) : (
            <>
              {hospitalizations.map((h) => (
                <HistoryRow
                  headerText={
                    h.date
                      ? formatPartialDate(h.date) + `${h.endDate ? ' - ' + formatPartialDate(h.endDate) : ''}`
                      : undefined
                  }
                  key={h._id}
                  active={!h.endDate || !h.endDate[0]}
                  rowButton={<HistoryRowControls document={h} startEdit={startEdit} />}
                  controlsMargin={false}
                >
                  <HistoryRowDataHospitalization d={h} />
                </HistoryRow>
              ))}
            </>
          )}
        </HistorySection>
      </PlatformConditional>
      <PlatformConditional platform="epilepsy">
        <React.Fragment>
          {[...extraEpSections].map((section) => (
            <HistorySection
              key={section.headerText}
              headerText={<FormattedMessage id={`comorbidity.${section.headerText}`} />}
              hasHistoryRows={true}
              newButton={
                <DocumentCreationButton
                  name={section.type}
                  text={`comorbidity.${section.buttonText}`}
                  onClick={startEdit({}, section.type)}
                  width={15}
                  height={3}
                  fontSize={14}
                  alternate={true}
                  disabled={disableAddNewOf(section.type)}
                  disabledTooltip={<FormattedMessage id="comorbidity.endOngoingToAddNew" />}
                />
              }
            >
              {[...section.documents].map((d) => (
                <HistoryRow
                  headerText={
                    d.date
                      ? formatPartialDate(d.date) + `${d.endDate ? ' - ' + formatPartialDate(d.endDate) : ''}`
                      : undefined
                  }
                  key={d._id}
                  active={!d.endDate || !d.endDate[0]}
                  rowButton={<HistoryRowControls document={d} startEdit={startEdit} />}
                  controlsMargin={false}
                >
                  {section.type === 'pregnancy' ? (
                    <HistoryRowDataPregnancy />
                  ) : section.type === 'contraception' ? (
                    <HistoryRowDataContraception d={d} />
                  ) : (
                    <></>
                  )}
                </HistoryRow>
              ))}
            </HistorySection>
          ))}
        </React.Fragment>
      </PlatformConditional>
      <PlatformConditional platform="sleepApnea">
        <HistorySection
          headerText={<FormattedMessage id="comorbidity.sickLeaves" />}
          hasHistoryRows={true}
          newButton={
            <DocumentCreationButton
              name="sickLeave"
              text={'comorbidity.newSickLeave'}
              onClick={startEdit({}, 'sickLeave')}
              width={15}
              height={3}
              fontSize={14}
              alternate={true}
            />
          }
        >
          {sickLeaves.map((s) => (
            <HistoryRow
              headerText={
                s.date
                  ? formatPartialDate(s.date) + `${s.endDate ? ' - ' + formatPartialDate(s.endDate) : ''}`
                  : undefined
              }
              key={s._id}
              active={!s.endDate || !s.endDate[0]}
              rowButton={<HistoryRowControls document={s} startEdit={startEdit} />}
              controlsMargin={false}
            >
              <HistoryRowDataSickLeave d={s} />
            </HistoryRow>
          ))}
        </HistorySection>
      </PlatformConditional>
    </React.Fragment>
  );
};
const History = connect(stateToHistoryProps)(_History);

const Comorbidity = ({ documents }: IOwnProps): JSX.Element => {
  const platform = useSelector((s: { session: ISessionStore }) => s.session.platforms?.selected);
  const headerId = getSectionTitle('comorbidity', platform);

  const documentsFiltered = getDocumentsByType(
    ['comorbidity', 'hospitalization', 'pregnancy', 'contraception', 'sickLeave'],
    documents ?? [],
  ) as Array<TDocument>;

  return (
    <FormEditingHandler name="comorbidity" documents={documents}>
      {(editing, startEdit, formData): JSX.Element => (
        <DocumentWrapper>
          <DocumentHeader
            name="comorbidity"
            headerId={
              docType(documents, editing) === 'comorbidity' && platform && platform === 'ninmt'
                ? 'comorbidity.diagnoses'
                : docType(documents, editing) === 'comorbidity'
                  ? 'comorbidity.comorbidity'
                  : docType(documents, editing) === 'hospitalization'
                    ? 'comorbidity.hospitalization'
                    : docType(documents, editing) === 'pregnancy'
                      ? 'comorbidity.pregnancy'
                      : docType(documents, editing) === 'contraception'
                        ? 'comorbidity.useOfContraceptive'
                        : docType(documents, editing) === 'sickLeave'
                          ? 'comorbidity.sickLeave'
                          : `comorbidity.${headerId}`
            }
            editing={editing}
          />
          {editing ? (
            <Form
              documents={documentsFiltered}
              document={documents && find((d: TDocument) => d._id === editing, documents)}
              formData={formData}
              editing={!!editing}
              medicationNames={documents ? getMedicationNames(documents) : []}
              diagnosis={documents ? getDiagnosisCodes(documents) : []}
              platform={platform}
            />
          ) : (
            <History
              documents={documentsFiltered.sort((n1, n2) => sortPartialDate(n1.date, n2.date)).reverse()}
              startEdit={startEdit}
              diagnosis={documents ? getDiagnosisCodes(documents) : []}
            />
          )}
        </DocumentWrapper>
      )}
    </FormEditingHandler>
  );
};

interface IOwnProps {
  documents?: (TDocument | IMedication | IDiagnosis)[]; // Medication documents are only used to get medication names for adverse effect medication selection and Diagnosis used at Comorbidity form if patient has J96.1 or J96.9
}

export default Comorbidity;
