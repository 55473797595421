import { path } from 'ramda';
import * as React from 'react';
import { TDocument } from '../../utils';
import GAF from './GAF';
import MADRS from './MADRS';

const InterviewSurveysForm = ({ document }: IOwnProps): JSX.Element | null =>
  path(['_type'], document) === 'madrs' ? <MADRS /> : path(['_type'], document) === 'gaf' ? <GAF /> : null;

interface IOwnProps {
  document: TDocument;
}

export default InterviewSurveysForm;
