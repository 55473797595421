import { path } from 'ramda';
import { FTDFormContent } from '../definitions';

/**
 * Return answer count of selected fields of document
 * that meets the answer given as parameter.
 * @param {{ [key: string]: string }} section - FTD section to check
 * @param {string[]} fields - Fields to check
 * @param {string} answer - Answers to count
 * @returns {number} - Answer count
 */
const answerCount = (section: { [key: string]: string | undefined }, fields: string[], answer: string): number => {
  const answers = fields.filter((f) => (section as { [key: string]: string | undefined })?.[f] === answer) ?? [];
  return answers.length;
};

/**
 * Ohjelma päättelee tämän seuraavasti
 * - "Kyllä" tulos edellyttää, että ydinkriteeri täyttyy (="Kyllä") ja että vähintään 3/6 kohdan II kriteereistä on "Kyllä"
 * - "Ei" tulos edellyttää, että  ydinkriteeri ei täy (="Ei") tai että vähintään 4/6 kohdan II kriteereistä on "Ei"
 * - Jos "Kyllä" tai "Ei" tulosten kriteerit eivät täyty, niin vastaus on "Ei tiedossa"
 * @param {IFTD} d - FTD document to be checked
 * @returns {'yes' | 'no' | 'unknown'} - Result based on values in the document
 */
export const coreCriteriaAndCriteriaForPossibleFTDFulfilled = (d: IFTD): 'yes' | 'no' | 'unknown' => {
  const section = FTDFormContent.possibleFTD.criteria ?? [];

  const coreCriteria = path(['coreCriteria', 'progressiveBehavioralChangeOrImpairedProcessingOfInformation'], d);

  if (coreCriteria === 'yes' && d.possibleFTD && answerCount(d.possibleFTD, section, 'yes') >= 3) return 'yes';

  if (coreCriteria === 'no' || (d.possibleFTD && answerCount(d.possibleFTD, section, 'no') >= 4)) return 'no';

  return 'unknown';
};

/**
 * Ohjelma päättelee tämän seuraavasti
 * - "Kyllä" tulos edellyttää, että  3/3 kohdan III kriteereistä on "Kyllä"
 * - "Ei" tulos edellyttää, että  vähintään 1/3 kohdan III kriteereistä on "Ei"
 * - Jos "Kyllä" tai "Ei" tulosten kriteerit eivät täyty, niin vastaus on "Ei tiedossa"
 * @param {IFTD} d - FTD document to be checked
 * @returns {'yes' | 'no' | 'unknown'} - Result based on values in the document
 */
export const coreCriteriaAndCriteriaForProbableFTDFulfilled = (d: IFTD): 'yes' | 'no' | 'unknown' => {
  const section = FTDFormContent.probableFTD.criteria ?? [];

  const criteria = {
    ...d.probableFTD,
    theCriteriaForPossibleFTDInSectionIIAreMet:
      (path(['probableFTD', 'theCriteriaForPossibleFTDInSectionIIAreMet'], d) as string | undefined) ??
      coreCriteriaAndCriteriaForPossibleFTDFulfilled(d),
  };

  const coreCriteria = path(['coreCriteria', 'progressiveBehavioralChangeOrImpairedProcessingOfInformation'], d);

  if (coreCriteria === 'yes' && d.probableFTD && answerCount(criteria, section, 'yes') >= 3) return 'yes';

  if (coreCriteria === 'no' || (d.probableFTD && answerCount(criteria, section, 'no') >= 1)) return 'no';

  return 'unknown';
};

/**
 * Ohjelma päättelee tämän seuraavasti
 * - "Kyllä" tulos edellyttää, että kohdan IV kriteereistä ensimmäinen on  "Kyllä" ja (joko toinen tai kolmas) on "Kyllä"
 * - "Ei" tulos edellyttää, että  kohdan IV kriteereistä ensimmäinen on  "Ei" tai (toinen ja kolmas) on "Ei"
 * - Jos "Kyllä" tai "Ei" tulosten kriteerit eivät täyty, niin vastaus on "Ei tiedossa"
 * @param {IFTD} d - FTD document to be checked
 * @returns {'yes' | 'no' | 'unknown'} - Result based on values in the document
 */
export const coreCriteriaAndCriteriaForCertainFTDFulfilled = (d: IFTD): 'yes' | 'no' | 'unknown' => {
  const section = FTDFormContent.certainFTD.criteria ?? [];

  const possibleOrProbable = (
    possible: 'yes' | 'no' | 'unknown',
    probable: 'yes' | 'no' | 'unknown',
  ): 'yes' | 'no' | 'unknown' =>
    [possible, probable].includes('yes') ? 'yes' : [possible, probable].includes('no') ? 'no' : 'unknown';

  const criteria = {
    ...d.certainFTD,
    criteriaForPossibleOrProbableFTDFulfilled:
      (path(['certainFTD', 'criteriaForPossibleOrProbableFTDFulfilled'], d) as string | undefined) ??
      possibleOrProbable(
        coreCriteriaAndCriteriaForPossibleFTDFulfilled(d),
        coreCriteriaAndCriteriaForProbableFTDFulfilled(d),
      ),
  };

  const coreCriteria = path(['coreCriteria', 'progressiveBehavioralChangeOrImpairedProcessingOfInformation'], d);

  if (
    coreCriteria === 'yes' &&
    d.certainFTD &&
    answerCount(criteria, [section[0]], 'yes') === 1 &&
    answerCount(criteria, section.slice(1), 'yes') >= 1
  )
    return 'yes';

  if (
    coreCriteria === 'no' ||
    (d.certainFTD &&
      (answerCount(d.certainFTD, [section[0]], 'no') === 1 || answerCount(d.certainFTD, section.slice(1), 'no') === 2))
  )
    return 'no';

  return 'unknown';
};

/**
 * Ohjelma päättelee tämän seuraavasti
 * - "Kyllä" tulos edellyttää, että vähintään yksi kohdan V kriteereistä  on  "Kyllä"
 * - "Ei" tulos edellyttää, että  kaikki  kohdan V kriteereistä ovat  "Ei".
 * - Jos "Kyllä" tai "Ei" tulosten kriteerit eivät täyty, niin vastaus on "Ei tiedossa"
 * @param {IFTD} d - FTD document to be checked
 * @returns {'yes' | 'no' | 'unknown'} - Result based on values in the document
 */
export const exclusionCriteriaForFTDFulfilled = (d: IFTD): 'yes' | 'no' | 'unknown' => {
  const section = FTDFormContent.exclusionCriteria.criteria ?? [];

  if (d.exclusionCriteria && answerCount(d.exclusionCriteria, section, 'yes') >= 1) return 'yes';

  if (d.exclusionCriteria && answerCount(d.exclusionCriteria, section, 'no') === 3) return 'no';

  return 'unknown';
};

const summaryResultPicker = (
  criteria: 'yes' | 'no' | 'unknown',
  exclusion: 'yes' | 'no' | 'unknown',
): 'yes' | 'no' | 'unknown' =>
  criteria === 'yes' && exclusion === 'no' ? 'yes' : criteria === 'no' || exclusion === 'yes' ? 'no' : 'unknown';

/**
 * Pick a value for a form row
 * @param {IFTD} d - FTD document to be checked
 * @param {string} name - Name of the row the value is picked for
 * @returns {'yes' | 'no' | 'unknown'} - Result for the row
 */
export const resultPicker = (d: IFTD, name: string): 'yes' | 'no' | 'unknown' => {
  const core = path(['coreCriteria', 'progressiveBehavioralChangeOrImpairedProcessingOfInformation'], d);
  const possible = coreCriteriaAndCriteriaForPossibleFTDFulfilled(d);
  const probable = coreCriteriaAndCriteriaForProbableFTDFulfilled(d);
  const certain = coreCriteriaAndCriteriaForCertainFTDFulfilled(d);
  const exclusion = exclusionCriteriaForFTDFulfilled(d);
  switch (name) {
    // Sections
    case 'coreCriteriaAndCriteriaForPossibleFTDFulfilled':
      return possible;
    case 'coreCriteriaAndCriteriaForProbableFTDFulfilled':
      return probable;
    case 'coreCriteriaAndCriteriaForCertainFTDFulfilled':
      return certain;
    case 'exclusionCriteriaForFTDFulfilled':
      return exclusion;
    // Summary
    case 'criteriaForCertainFTDFulfilled':
      return summaryResultPicker(certain, exclusion);
    case 'criteriaForProbableFTDFulfilled':
      return summaryResultPicker(probable, exclusion);
    case 'criteriaForPossibleFTDFulfilled':
      return summaryResultPicker(possible, exclusion);
    case 'coreCriteriaForFTDFulfilled':
      return core === 'yes' ? 'yes' : core === 'no' ? 'no' : 'unknown';
    default:
      return 'unknown';
  }
};
