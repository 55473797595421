import * as React from 'react';

import HFNCTherapy from './HFNCTherapy';
import MADTherapy from './MandibularAdvancementDevice';
import OtherTreatment from './OtherTreatment';
import OxygenTherapy from './OxygenTherapy';
import PAPTherapy from './PAPTherapy';
import PatientDoesNotWantRespiratorySupportTherapy from './PatientDoesNotWantRespiratorySupportTherapy';
import { TDocument } from '../../utils';
import { path } from 'ramda';
import SurgicalTreatment from './SurgicalTreatment';

const TreatmentForm = ({ document }: IOwnProps): JSX.Element | null =>
  path(['_type'], document) === 'papTherapy' ? (
    <PAPTherapy />
  ) : path(['_type'], document) === 'hfncTherapy' ? (
    <HFNCTherapy />
  ) : path(['_type'], document) === 'oxygenTherapy' ? (
    <OxygenTherapy />
  ) : path(['_type'], document) === 'madTherapy' ? (
    <MADTherapy />
  ) : path(['_type'], document) === 'surgicalTreatment' ? (
    <SurgicalTreatment />
  ) : path(['_type'], document) === 'otherTreatment' ? (
    <OtherTreatment />
  ) : path(['_type'], document) === 'patientDoesNotWantRespiratorySupportTherapy' ? (
    <PatientDoesNotWantRespiratorySupportTherapy />
  ) : null;

interface IOwnProps {
  document: TDocument;
}

export default TreatmentForm;
