import { path } from 'ramda';
import * as React from 'react';

import { TDocument } from '../../utils';

import DEXAForm from './DEXA';
import CMAPForm from './CMAP';
import MuscleImagingForm from './MuscleImaging';
import EchocardiographyForm from './Echocardiography';
import ScoliosisForm from './Scoliosis';
import MriForm from './Mri';
import TTForm from './TT';
import ScintigraphyForm from './Scintigraphy';
import PelvisForm from './Pelvis';
import ThymusCTForm from './ThymusCT';
import ThymusMRIForm from './ThymusMRI';

const ImagingForm = ({ formData, document }: IOwnProps): JSX.Element | null =>
  path(['_type'], document) === 'dexa' ? (
    <DEXAForm formData={formData} />
  ) : path(['_type'], document) === 'cmap' ? (
    <CMAPForm formData={formData} />
  ) : path(['_type'], document) === 'muscleImaging' ? (
    <MuscleImagingForm formData={formData} />
  ) : path(['_type'], document) === 'echocardiography' ? (
    <EchocardiographyForm formData={formData as IFormData<IEchocardiography>} />
  ) : path(['_type'], document) === 'scoliosis' ? (
    <ScoliosisForm formData={formData} />
  ) : path(['_type'], document) === 'pelvis' ? (
    <PelvisForm formData={formData} />
  ) : path(['_type'], document) === 'mri' ? (
    <MriForm formData={formData} />
  ) : path(['_type'], document) === 'tt' ? (
    <TTForm formData={formData} />
  ) : path(['_type'], document) === 'scintigraphy' ? (
    <ScintigraphyForm formData={formData as IFormData<IScintigraphy>} />
  ) : path(['_type'], document) === 'thymusCt' ? (
    <ThymusCTForm formData={formData as IFormData<IThymusCT>} />
  ) : path(['_type'], document) === 'thymusMri' ? (
    <ThymusMRIForm formData={formData as IFormData<IThymusMRI>} />
  ) : null;
interface IOwnProps {
  formData: IFormData<TDocument>;
  document: TDocument;
  viewing?: boolean;
}

export default ImagingForm;
