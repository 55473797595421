import { filter, path, find } from 'ramda';
import * as React from 'react';

import DocumentHeader from '../../../components/DocumentHeader';
import DocumentWrapper from '../../../components/DocumentWrapper';
import FormEditingHandler from '../../../containers/FormEditingHandler';

import MotorForm from './Form';

import HineMMHistory from './HistoryRowData/HINEMM';
import RULMMMHistory from './HistoryRowData/RULM';
import CHOPINTENDHistory from './HistoryRowData/CHOPINTEND';
import HFMSEHistory from './HistoryRowData/HFMSE';
import MWTHistory from './HistoryRowData/6MWT';
import NSAAHistory from './HistoryRowData/NSAA';
import MFMHistory from './HistoryRowData/MFM';
import PlatformConditional from '../../../components/PlatformConditional';
import { TDocument } from '../utils';

const docType = (documents: Array<TDocument>, editing: string, view?: IView): string | undefined =>
  documents && editing
    ? path(
        ['_type'],
        find((d: TDocument) => d._id === editing, documents),
      ) || undefined
    : documents && view?.viewing
      ? path(
          ['_type'],
          find((d: TDocument) => d._id === view?.viewing, documents),
        ) || undefined
      : undefined;

const MotorHistory = (
  documents: Array<TDocument>,
  startEdit: (document: TAnyObject) => (e: React.MouseEvent<Element, MouseEvent>) => void,
  view?: IView,
  diagnoses?: Array<TDiagnosis | undefined>,
): JSX.Element => {
  const HineMMDocs = documents && filter((d) => d._type === 'hine_mm', documents);
  const RULMMMDocs = documents && filter((d) => d._type === 'rulm', documents);
  const CHOPINTENDDocs = documents && filter((d) => d._type === 'chop_intend', documents);
  const HFMSEDocs = documents && filter((d) => d._type === 'hfms_e', documents);
  const MWTDocs = documents && filter((d) => d._type === 'mwt', documents);
  const NSAADocs = documents && filter((d) => d._type === 'nsaa', documents);
  const MFMDocs = documents && filter((d) => d._type === 'mfm', documents);
  return (
    <React.Fragment>
      <PlatformConditional platform="sma">
        <React.Fragment>
          <HineMMHistory documents={HineMMDocs as IHINEMM[]} startEdit={startEdit} view={view} />

          <RULMMMHistory documents={RULMMMDocs as IRULM[]} startEdit={startEdit} view={view} />

          <CHOPINTENDHistory documents={CHOPINTENDDocs as ICHOPINTEND[]} startEdit={startEdit} view={view} />

          <HFMSEHistory documents={HFMSEDocs as IHFMSE[]} startEdit={startEdit} view={view} />

          <MFMHistory documents={MFMDocs as IMFM[]} startEdit={startEdit} view={view} />

          {(diagnoses || [])?.includes('G12.1') && (
            <MWTHistory documents={MWTDocs as I6MWT[]} startEdit={startEdit} view={view} />
          )}
        </React.Fragment>
      </PlatformConditional>
      <PlatformConditional platform="dmd">
        <React.Fragment>
          <MWTHistory documents={MWTDocs as I6MWT[]} startEdit={startEdit} view={view} />

          <MFMHistory documents={MFMDocs as IMFM[]} startEdit={startEdit} view={view} />

          <div style={{ marginTop: '5rem' }}>
            <NSAAHistory documents={NSAADocs as INSAA[]} startEdit={startEdit} view={view} />
          </div>
        </React.Fragment>
      </PlatformConditional>
    </React.Fragment>
  );
};

const Motor = ({ documents }: IOwnProps): JSX.Element => {
  const diagnoses = documents.filter((d) => d._type === 'diagnosis').map((d: IDiagnosis) => d.diagnosis);

  return (
    <FormEditingHandler name="motor" documents={documents}>
      {(editing, startEdit, formData, view): JSX.Element => (
        <DocumentWrapper>
          <DocumentHeader
            name={'motor'}
            headerId={
              docType(documents, editing, view) === 'hine_mm'
                ? 'motor.hineMM'
                : docType(documents, editing, view) === 'rulm'
                  ? 'motor.rulm'
                  : docType(documents, editing, view) === 'chop_intend'
                    ? 'motor.chopINTEND'
                    : docType(documents, editing, view) === 'hfms_e'
                      ? 'motor.hfmsE'
                      : docType(documents, editing, view) === 'mwt'
                        ? 'motor.MWT'
                        : docType(documents, editing, view) === 'nsaa'
                          ? 'motor.nsaa'
                          : docType(documents, editing, view) === 'mfm'
                            ? 'motor.mfm'
                            : 'motor.title'
            }
            editing={editing}
            editButtons={<div />}
          />
          {editing ? (
            <MotorForm
              formData={formData}
              document={documents && (find((d: TDocument) => d._id === editing, documents) as TDocument)}
              editing={editing}
            />
          ) : null}

          {view?.viewing ? (
            <MotorForm
              formData={{ document: view.document, onChange: (): string => '' }}
              document={documents && (find((d: TDocument) => d._id === view?.viewing, documents) as TDocument)}
              viewing={!!view.viewing}
              editing={editing}
            />
          ) : null}

          {!editing && !view?.viewing ? MotorHistory(documents, startEdit, view, diagnoses) : undefined}
        </DocumentWrapper>
      )}
    </FormEditingHandler>
  );
};

interface IOwnProps {
  documents: Array<TDocument>;
}

export default Motor;
