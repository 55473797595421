import { gte, intersperse, isNil, path, values } from 'ramda';
import * as React from 'react';

import FormRow from '../../../../../../../components/FormRow';
import { Container, Item } from '../../../../../../../components/Grid';
import InputHandler from '../../../../../../../components/InputHandler';

import { a2String } from '../../../../../../../utility/string';
import { valueFilter } from '../utils';
import DosingInstructions from './DosingInstructions';

const formatStrenghtString = (s: IStrengths): string => a2String(intersperse(' / ', values(s)));

// Format strengths to string and return string based on array item index
const strengthOptionFormatter =
  (strArr: IStrengths[]) =>
  (index: number | string): string =>
    formatStrenghtString(strArr[index as number]);

const changeValue =
  (formData: IOwnProps['formData'], editIndex: number, name: string) =>
  (values: TOnChangeValues): void => {
    let value = values[name];
    if (!isNil(value)) {
      const regimen = formData.document.regimen ? [...formData.document.regimen] : [];
      if (regimen) {
        if (name === 'strengths') {
          const newStrengths = JSON.parse(JSON.stringify(regimen[editIndex].strengths ?? []));
          const strengthFormatted = { [formData.document.medicationSubstances ?? 's']: value.toString() };
          if (newStrengths.length > 0) {
            newStrengths[0] = strengthFormatted;
          } else {
            newStrengths.push(strengthFormatted);
          }
          value = newStrengths;
        }
        regimen[editIndex] = { ...regimen[editIndex], [name]: value } as IRegimenBasics & IRegimenDefault;
        if (
          formData.document.isClinicalStudy &&
          Array.isArray((regimen[editIndex] as IRegimenBasics & IRegimenDefault).dosages)
        ) {
          regimen[editIndex] = { ...regimen[editIndex], dosages: [] } as IRegimenBasics & IRegimenDefault;
        }
        formData.onChange?.({ regimen: regimen });
      }
    }
  };

export const StrengthSelect = ({
  formData,
  availableStrengths,
  strengthsOnChange,
  strengths,
  editIndex,
}: IOwnProps): JSX.Element => {
  return (
    <FormRow title="medication.strength" headerWidth={3}>
      <Container alignItems="center">
        <Item>
          {path(['isClinicalStudy', 0], formData.document) === true ? (
            <InputHandler
              type="TextField"
              name="strengths"
              formData={{
                onChange: changeValue(formData, editIndex ?? 0, 'strengths'),
                document: {
                  strengths:
                    path(
                      ['regimen', editIndex ?? 0, 'strengths', 0, formData.document.medicationSubstances ?? 's'],
                      formData.document,
                    ) || '',
                },
              }}
              editing={true}
              fullWidth={true}
              placeholder="medication.strength"
            />
          ) : (
            <InputHandler
              type="Select"
              name="strengths"
              editing={true}
              options={availableStrengths.map((_, i) => i)} // Get indexes from strengths
              optionFormatter={strengthOptionFormatter(availableStrengths)}
              formData={{
                onChange: strengthsOnChange,
                document: { strengths: valueFilter(availableStrengths, strengths, gte) }, // Fetch indexes of strength in form data, from all strengths
              }}
              placeholder="medication.strength"
              autoSelectSingle
            />
          )}
        </Item>
        <DosingInstructions medication={formData.document} />
      </Container>
    </FormRow>
  );
};

interface IOwnProps {
  formData: { onChange?: any; document: IMedication };
  availableStrengths: { [key: string]: string }[];
  strengthsOnChange: (e: TOnChangeValues) => void;
  strengths: IStrengths[];
  editIndex?: number;
}
