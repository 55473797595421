import { path } from 'ramda';
import * as React from 'react';
import { TDocument } from '../../utils';
import UHDRSFunctionalAssessmentForm from './UHDRSFunctionalAssessment';
import UHDRSFunctionalCapacityForm from './UHDRSFunctionalCapacity';
import UHDRSIndependenceScaleForm from './UHDRSIndependenceScale';
import ModifiedRankinScaleForm from './ModifiedRankinScale';
import ModifiedChildrensGlobalAssessmentScaleForm from './ModifiedChildrensGlobalAssessmentScale';

const FunctionalPerformanceForm = ({ formData, document, viewing = false }: IOwnProps): JSX.Element | null =>
  path(['_type'], document) === 'uhdrsFunctionalAssessment' ? (
    <UHDRSFunctionalAssessmentForm formData={formData as IFormData<IUHDRSFunctionalAssessment>} viewing={viewing} />
  ) : path(['_type'], document) === 'uhdrsIndependenceScale' ? (
    <UHDRSIndependenceScaleForm formData={formData as IFormData<IUHDRSIndependenceScale>} viewing={viewing} />
  ) : path(['_type'], document) === 'uhdrsFunctionalCapacity' ? (
    <UHDRSFunctionalCapacityForm formData={formData as IFormData<IUHDRSFunctionalCapacity>} viewing={viewing} />
  ) : path(['_type'], document) === 'modifiedRankinScale' ? (
    <ModifiedRankinScaleForm formData={formData as IFormData<IModifiedRankinScale>} viewing={viewing} />
  ) : path(['_type'], document) === 'modifiedChildrensGlobalAssessmentScale' ? (
    <ModifiedChildrensGlobalAssessmentScaleForm
      formData={formData as IFormData<IModifiedChildrensGlobalAssessmentScale>}
      viewing={viewing}
    />
  ) : null;

interface IOwnProps {
  formData: IFormData<TDocument>;
  document: TDocument;
  viewing?: boolean;
}

export default FunctionalPerformanceForm;
