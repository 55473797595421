import { path } from 'ramda';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { Item } from '../../../../components/Grid';
import HistoryRowControls from '../../../../components/HistoryRowControls';
import HistoryRowListing from '../../../../components/HistoryRowListing';
import HistorySection from '../../../../components/HistorySection';

import DocumentCreationButton from '../../../../components/DocumentCreationButton';

const UPDRSV = ({ documents, startEdit, view }: IOwnProps): JSX.Element => (
  <HistorySection
    headerText={<FormattedMessage id={'updrs.updrsV'} />}
    newButton={
      <DocumentCreationButton
        name="updrs_v"
        text={'updrs.newUpdrsV'}
        onClick={startEdit({}, 'updrs_v')}
        width={15}
        height={3}
        fontSize={14}
        alternate={true}
      />
    }
  >
    <HistoryRowListing
      documents={documents}
      headers={
        <>
          <Item xs={4}>
            <FormattedMessage id={'updrs.score'} />
          </Item>
          <Item xs={8}>
            <FormattedMessage id={'general.definition'} />
          </Item>
        </>
      }
      contentFormat={(d: IUPDRSV): JSX.Element => (
        <>
          <Item xs={4}>{path(['value'], d) ?? '-'}</Item>
          <Item xs={8} style={{ paddingRight: '1rem' }}>
            {path(['value'], d) || path(['value'], d) === 0 ? (
              <FormattedMessage id={`updrs.opts.v.${path(['value'], d)}`} />
            ) : (
              '-'
            )}
          </Item>
        </>
      )}
      makeDate
      makeControls
      historyRowControls={(d: IControlProps) => <HistoryRowControls document={d} startEdit={startEdit} view={view} />}
    />
  </HistorySection>
);

interface IOwnProps {
  documents: IUPDRSV[];
  startEdit: (document: TAnyObject, name?: string) => (e: React.MouseEvent<Element, MouseEvent>) => void;
  view?: IView;
}

export default UPDRSV;
