import * as React from 'react';
import { PrimitiveType, useIntl } from 'react-intl';
import { RootState, useAppDispatch as useDispatch, useAppSelector as useSelector } from 'Store/index';

import colors from '../../../config/theme/colors';
import InviteForm from './Form/Invite';
import TaskListForm, { TaskListFormProps } from './Form/TaskList';
import MyServiceHistory from './HistoryRowData';
import SurveyForm from './Form/Survey';
import { actions } from 'Store/myapp/actions';
import DocumentHeader from 'Components/DocumentHeader';
import DocumentWrapper from 'Components/DocumentWrapper';
import Toolbar from 'Components/Toolbar';
import { makeDocumentHeader, makeDocumentEditingInfotext, TSurveyType, makeDocumentInfotext } from '../util';
import browserLanguage from 'Utility/browserLanguage';
import { isLocaleKey } from 'neuro-schemas';

const isInvite = (document: any): document is IInvite => 'sendingMethod' in document;
const isTasklist = (document: any): document is ITaskList =>
  'deadline' in document || 'recurringTaskDeadline' in document || 'tasks' in document;

interface IEditing {
  type: 'invite' | 'taskList' | 'survey' | string;
  data: TSurveyType | ITaskList | IInvite | null;
}

export const MyServiceContext = React.createContext<IMyServiceContext>({
  fm: (m: string) => m,
  editing: null,
  viewing: null,
  setEditingObj: () => '',
  setEditingData: null,
  setViewingObj: () => '',
  locale: 'fi',
  patientId: '',
  mysqUserId: '',
  orgId: '',
  platform: undefined,
});

export interface IMyServiceContext {
  fm: (
    m: string,
    n?:
      | string
      | number
      | Record<
          string,
          | PrimitiveType
          | JSX.Element
          | ((chunks: string | Array<string | JSX.Element> | JSX.Element) => JSX.Element | string)
        >,
  ) => string;
  editing: IEditing | null;
  viewing: IEditing | null;
  setEditingObj: (ed: IEditing) => void;
  setEditingData: ((data: IEditing['data']) => void) | null;
  setViewingObj: (ed: IEditing | null) => void;
  locale: string;
  patientId: IPatientAPI['id'];
  mysqUserId: string;
  orgId: string;
  platform: Platform | undefined;
}

const MyService = ({ documents }: IOwnProps): JSX.Element => {
  const { formatMessage, locale } = useIntl();
  const fm = (
    id: string,
    n?:
      | string
      | number
      | Record<
          string,
          | PrimitiveType
          | JSX.Element
          | ((chunks: string | Array<string | JSX.Element> | JSX.Element) => JSX.Element | string)
        >,
  ): string => {
    if (n && typeof n === 'object') return formatMessage({ id }, n as Record<string, any>) as string;
    return formatMessage({ id }, { n });
  };

  const [editing, setEditing] = React.useState<IEditing | null>(null);
  const [viewing, setViewing] = React.useState<IEditing | null>(null);
  const [inviteErrors, setInviteErrors] = React.useState<Array<{ method: 'sms' | 'email'; statusCode: number }>>([]);

  const setEditingObj = (ed: IEditing) => setEditing(ed);
  const setEditingData = (data: IEditing['data']) => editing && setEditing({ ...editing, data });

  const setViewingObj = (ed: IEditing | null) => setViewing(ed);

  const cancelEdit = () => {
    setEditing(null);
  };

  const patientId = useSelector((s: IState) => s.patient.data?.id) || '';
  const mysqUserId = useSelector((s: IState) => s.myapp.mysqUserId) || '';
  const orgId = useSelector((s: IState) => s.session.data?.orgid) || '';
  const platform = useSelector((s: IState) => s.session.platforms?.selected);
  const users = useSelector((s: IState) => s.session.orgUsers);
  const dispatch = useDispatch();

  const uiLanguage = useSelector((state: IState) => state.settings.userSettings.uiLanguage) || browserLanguage;
  const taskStimulusFormSettings =
    useSelector((state: RootState) => state.settings.orgSettings.settings?.taskUiSettings) ?? undefined;

  const sendAndSave = () => {
    if (!editing) return;
    if (editing.type === 'invite') {
      const tasklistData = (isTasklist(editing.data) ? editing.data : null) as ITaskList | null;
      const newTasklist = () => {
        if (
          tasklistData?.tasks &&
          patientId &&
          orgId &&
          (tasklistData?.deadline || tasklistData?.recurringTaskDeadline)
        ) {
          actions.newTasklist(tasklistData, dispatch).then((res) => {
            res && setEditing(null);
          });
        }
      };
      if (isInvite(editing.data)) {
        const data = editing.data;
        const sendingMethod = data.sendingMethod;

        Promise.all(
          sendingMethod.map((medium) => {
            const carrier = data[medium] as string;
            if (carrier) {
              return actions.newInvite(carrier, medium.toUpperCase(), orgId, dispatch, uiLanguage);
            } else return Promise.resolve();
          }),
        )
          .then((responses) => {
            responses.forEach((status, i) => {
              const method = sendingMethod[i];
              if (status && status !== 200) {
                const otherErrors = inviteErrors.filter((err) => err.method !== method);
                setInviteErrors([...otherErrors, { method, statusCode: status }]);
              } else {
                const otherErrors = inviteErrors.filter((err) => err.method !== method);
                setInviteErrors(otherErrors);
              }
            });

            // Send new tasklist after invites have been sent
            newTasklist();
          })
          .catch(() => {
            setEditing(null);
          });
      }

      if (editing?.data && !('sendingMethod' in editing.data)) {
        // Test and send tasklist if no invite methods are selected
        newTasklist();
      }
    } else if (editing.type === 'taskList') {
      const tasklistData = isTasklist(editing.data) ? editing.data : null;
      if (tasklistData && patientId && orgId && (tasklistData?.deadline || tasklistData?.recurringTaskDeadline)) {
        if (tasklistData.id) {
          actions.updateTasklist(tasklistData, dispatch).then((res) => {
            res && setEditing(null);
          });
        } else {
          actions.newTasklist(tasklistData, dispatch).then((res) => {
            res && setEditing(null);
          });
        }
      }
    } else if (editing.type) {
      // Surveys
      const surveyData = editing.data;

      const id = surveyData && '_id' in surveyData ? surveyData._id : undefined;
      if (id) {
        actions.putNewCommit(editing.type, id, surveyData, dispatch).then(() => {
          setEditing(null);
        });
      } else {
        actions.putNewMyDocument(editing.type, surveyData, dispatch).then(() => {
          setEditing(null);
        });
      }
    }

    setEditing(null);
  };

  const sentInvites = useSelector((s: IState) => s.myapp.sentInvites);

  const [selectedTab, setSelectedTab] = React.useState<number>(0);

  const saveButtonDisabled = (): boolean => {
    const data = (editing?.data || {}) as ITaskList;
    return (
      (editing?.type === 'taskList' || (editing?.type === 'invite' && !!data.title)) &&
      (!editing?.data ||
        (data.recurring && !(data.recurringTaskDeadline && data.weeklySurveyInterval)) ||
        !data.deadline ||
        !data.description ||
        !data.sendDate)
    );
  };

  const sortedAndMergedDocuments = useSelector((s) => s.documents?.sortedAndMergedDocuments);

  let epilepsySeizuresNoNameByPatient = false;

  if (platform === 'epilepsy') {
    const seizureTypeDocs: Array<ISeizureType> =
      sortedAndMergedDocuments?.filter((d) => d._type === 'seizureType') || [];
    epilepsySeizuresNoNameByPatient = (seizureTypeDocs || []).some((std) => !std.nameByPatient);
  }

  const tasklistProps: TaskListFormProps = {
    ninmt: taskStimulusFormSettings?.ninmt,
    sleepApnea: taskStimulusFormSettings?.sleepApnea,
  };

  return (
    <>
      <Toolbar
        current={'myService'}
        editing={editing ? 'editing' : undefined}
        view={viewing ? { viewing: 'true', endView: () => () => setViewing(null) } : undefined}
        cancelButtonCallback={cancelEdit}
        cancelDraft={() => () => null}
        saveDraft={() => () => null}
        saveButtonCallback={sendAndSave}
        saveButtonDisabled={saveButtonDisabled()}
      />
      <MyServiceContext.Provider
        value={{
          fm: fm,
          editing,
          viewing,
          setEditingObj,
          setEditingData: editing ? setEditingData : null,
          setViewingObj,
          locale: locale,
          patientId: patientId,
          mysqUserId: mysqUserId,
          orgId: orgId,
          platform: platform,
        }}
      >
        <DocumentWrapper>
          {!editing && !viewing ? (
            <div style={{ color: colors.primary, fontWeight: 600, lineHeight: '1.6rem' }}>
              {fm('myService.appName')}
            </div>
          ) : undefined}
          <DocumentHeader
            name={'myService'}
            headerId={
              editing
                ? makeDocumentHeader(editing.type)
                : viewing
                  ? makeDocumentHeader(viewing.type)
                  : platform === 'epilepsy'
                    ? 'myService.titleEpilepsy'
                    : 'myService.title'
            }
            headerWidth={10}
            editing={undefined}
            infoText={
              editing
                ? makeDocumentEditingInfotext(
                    editing.type,
                    !(!sentInvites || sentInvites.length === 0) || platform === 'epilepsy',
                  )
                : makeDocumentInfotext(platform, {
                    epilepsySeizuresNoNameByPatient,
                    docType: viewing?.type,
                    docData: viewing?.data as TSurveyType,
                    locale: isLocaleKey(locale) ? locale : undefined,
                  })
            }
          />
          {editing && editing.type === 'invite' ? (
            <InviteForm taskListSettings={tasklistProps} />
          ) : editing && editing.type === 'taskList' ? (
            <TaskListForm taskListSettings={tasklistProps} />
          ) : editing && typeof editing.type === 'string' ? (
            <SurveyForm documents={documents} mysqUserId={mysqUserId} users={users || []} />
          ) : undefined}

          {viewing && typeof viewing.type === 'string' ? (
            <SurveyForm documents={documents} mysqUserId={mysqUserId} users={users || []} />
          ) : undefined}

          {!(editing || viewing) ? (
            <MyServiceHistory
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              users={users || []}
              inviteErrors={inviteErrors}
            />
          ) : undefined}
        </DocumentWrapper>
      </MyServiceContext.Provider>
    </>
  );
};

interface IOwnProps {
  documents: Array<IRTMS | ITDCS | ISymptomsAndLocations | IDoctorsOrder | ININMTTreatmentPeriod>;
}

export default MyService;
