import { filter, find } from 'ramda';
import * as React from 'react';
import DocumentHeader from '../../../components/DocumentHeader';
import DocumentWrapper from '../../../components/DocumentWrapper';
import PlatformConditional from '../../../components/PlatformConditional';
import FormEditingHandler from '../../../containers/FormEditingHandler';
import { docType, TDocument } from '../utils';
import MotorFuncHuntForm from './Form';
import UHDRSHistory from './HistoryRowData/UHDRS';

const MotorFuncHuntHistory = (
  documents: Array<TDocument>,
  startEdit: (document: TAnyObject) => (e: React.MouseEvent<Element, MouseEvent>) => void,
  view?: IView,
): JSX.Element => {
  const UHDRSDocs = documents && filter((d) => d._type === 'uhdrsMotorRatingScale', documents);
  return (
    <React.Fragment>
      <PlatformConditional platform="huntington">
        <React.Fragment>
          <UHDRSHistory documents={UHDRSDocs as IUHDRSMotorRatingScale[]} startEdit={startEdit} view={view} />
        </React.Fragment>
      </PlatformConditional>
    </React.Fragment>
  );
};

const MotorFuncHunt = ({ documents }: IOwnProps): JSX.Element => {
  return (
    <FormEditingHandler name="motorFunctionHuntington" documents={documents}>
      {(editing, startEdit, formData, view): JSX.Element => (
        <DocumentWrapper>
          <DocumentHeader
            name={'motorFunctionHuntington'}
            headerId={
              docType(documents, editing, view) === 'uhdrsMotorRatingScale'
                ? 'motorFunctionHuntington.uhdrs'
                : 'motorFunctionHuntington.title'
            }
            editing={editing}
            editButtons={<div />}
          />
          {editing ? (
            <MotorFuncHuntForm
              formData={formData}
              document={documents && (find((d: TDocument) => d._id === editing, documents) as TDocument)}
              editing={editing}
            />
          ) : null}

          {view?.viewing ? (
            <MotorFuncHuntForm
              formData={{ document: view.document, onChange: (): string => '' }}
              document={documents && (find((d: TDocument) => d._id === view?.viewing, documents) as TDocument)}
              viewing={!!view.viewing}
            />
          ) : null}

          {!editing && !view?.viewing ? MotorFuncHuntHistory(documents, startEdit, view) : undefined}
        </DocumentWrapper>
      )}
    </FormEditingHandler>
  );
};

interface IOwnProps {
  documents: Array<TDocument>;
}

export default MotorFuncHunt;
