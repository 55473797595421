import { path } from 'ramda';
import * as React from 'react';
import { TDocument } from '../../utils';
import CarbonDioxideMeasurementForm from './CarbonDioxideMeasurement';
import NightOximetryForm from './NightOximetry';
import SleepPolygraphyForm from './SleepPolygraphy';
import SleepStudyForm from './SleepStudy';
import SpirometryForm from './Spirometry';
import DiagnosticExaminationForm from './DiagnosticExamination';

const ClpAndCnpTestsForm = ({
  formData,
  document,
  viewing = false,
  documents,
  diagnosticExamKey,
}: IOwnProps): JSX.Element | null => {
  return path(['_type'], document) === 'sleepPolygraphy' ? (
    <SleepPolygraphyForm formData={formData} viewing={viewing} documents={documents} />
  ) : path(['_type'], document) === 'sleepStudy' ? (
    <SleepStudyForm formData={formData} viewing={viewing} documents={documents} />
  ) : path(['_type'], document) === 'spirometry' ? (
    <SpirometryForm formData={formData} viewing={viewing} />
  ) : path(['_type'], document) === 'nightOximetry' ? (
    <NightOximetryForm formData={formData} viewing={viewing} />
  ) : path(['_type'], document) === 'carbonDioxideMeasurement' ? (
    <CarbonDioxideMeasurementForm formData={formData} viewing={viewing} />
  ) : path(['_type'], document) === 'diagnosticExamination' ? (
    <DiagnosticExaminationForm
      formData={formData as IFormData<IDiagnosticExamination>}
      viewing={viewing}
      diagnosticExamKey={diagnosticExamKey}
    />
  ) : null;
};

interface IOwnProps {
  formData: IFormData<TDocument>;
  document: TDocument;
  viewing?: boolean;
  documents: Array<any>;
  diagnosticExamKey?: TDiagnosticExamKeys;
}

export default ClpAndCnpTestsForm;
